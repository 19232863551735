export function getHomeRoute() {
    return '/'
}

export function getClassHomeRoute() {
    return '/class';
}

export function getSubPageRoute() {
    return '/page'
}

export function getPlayerIntroRoute() {
    return '/player/enter'
}

export function getGameMasterRoute() {
    return '/game-master'
}

export function getGameMasterLiveGameRoute(gameCode: string = ':gameCode') {
    return `/game-master/game/${gameCode}`;
}

export function getPlayerGameRoute() {
    return `/player/game`;
}

export function getPlayerMenuRoute() {
    return `/player/menu`;
}

export function getPlayerGameTitleRoute() {
    return `/player/game/title`;
}

export function getPlayerGameReset(gameCode: string = ':gameCode') {
    return `/player/game/${gameCode}/reset`;
}

export function getCreateNewGameRoute() {
    return `/game-master/new-game`
}

export function getClientZoneRoute() {
    return '/redakcja';
}

export function getSurveyRaportRoute(id: string = ':surveyId') {
    return `/redakcja/ankieta/${id}`;
}

export function getPrivatePoliticsRoute() {
    return '/polityka-prywatnosci';
}

export function getOpenPlayerIntroRoute() {
    return '/openplayer';
}
