import { useQueryClient, useMutation } from "@tanstack/react-query";
import Network from "../../network";

export default function useNextChapter() {
    const client = useQueryClient();
    const { mutate, isLoading } = useMutation({
        mutationFn: () => {
            return Network.nextChapter();
        },
        onSuccess() {
            client.invalidateQueries(['game-step'], {
                refetchType: 'all'
            }, {});
        }
    });

    return {
        nextChapter: mutate,
        isLoading
    }
}
