import { Link } from "react-router-dom";
import styled from "styled-components";
import useCookieAccept from "../../modules/main/hooks/use-cookie-accept";
import { getPrivatePoliticsRoute } from "../../routes/routes";

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    font-size: 14pt;
    font-weight: normal;

    .cookie-box {
        position: absolute;

        left: 0;
        right: 0;
        bottom:0;

        padding: 1em 2em;
        background: white;

        box-shadow: 0 0 5px 0px rgba(0,0,0,.8);

        gap: 1.5em;

        display: grid;
        grid-template-columns: 1fr auto;


    }

    .buttons {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    button {
        font-size: 1em;
        text-decoration: none;
        padding: .5em 1em;
        border-radius: 1.6em;
        border: none;
        background: var(--color3);
        color: white;
        text-transform: uppercase;

        display: block;

        box-shadow: 0 .18em .15em 0 rgba(53,73,94, .45);

        &:hover {
            cursor: pointer;
            background: var(--color1);
        }
    }

    @media(orientation: portrait) {
        .cookie-box {
            grid-template-columns: 1fr;
        }
    }

`;

export default function CookiePopup() {
    const { cookieAccepped, acceptCookies } = useCookieAccept();

    return <>
        {!cookieAccepped && <Container>
            <div className="cookie-box">
                <p>Używamy plików cookies, aby ułatwić Ci korzystanie z naszego serwisu oraz do celów statystycznych. Jeśli nie blokujesz tych plików, to zgadzasz się na ich użycie oraz zapisanie w pamięci urządzenia. Pamiętaj, że możesz samodzielnie zarządzać cookies, zmieniając ustawienia przeglądarki. Więcej informacji w naszej <Link to={getPrivatePoliticsRoute()}>polityce prywatności</Link>.</p>
                <div className="buttons">
                    <button onClick={acceptCookies}>Dalej</button>
                </div>
            </div>
        </Container>}
    </>
}
