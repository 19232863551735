import styled from "styled-components"
import classNames from 'classnames';
import { clearBlockTags, parseInlineTags } from "./rich-text";

const Container = styled.div`

    margin: .5em 0;

    display: grid;

    grid-template-rows: 1.5em auto;

    gap: .5em 25px;

    /* grid-template-columns: minmax(20px, auto) 1fr 1fr; */

    .name {
        font-weight: 300;
        color: #a5a5a5;

        span {
                width: 112%;
                display: inline-block;
                transform-origin: 0 50%;
                transform: scale(.89, 1)
            }
    }

    .avatar {
        display: flex;
        flex-direction: column;
        justify-content: center;

        img {
            width: 75px;
            max-width: 20vw;

            @media (min-width: 768px) {
                width: 125px;
            }
        }
    }

    .message {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        color: var(--color4);

        span {
                width: 112%;
                display: inline-block;
                transform-origin: 0 50%;
                transform: scale(.89, 1)
            }
    }

    .message-box {
        padding: 1.1em .5em;
        border-radius: 1em;

        a {
            color: var(--dialog-link-color);
            text-decoration: none;

            &:hover {
                color: var(--dialog-link-hover-color);
            }
        }
    }

    &.main {
        .message-box {
            background: var(--color5);
        }
    }

    &.secondary {
        .message-box {
            background: var(--color1);
        }
    }

    &.avatar-left {
        grid-template-columns: auto 3fr;

        .avatar {
            grid-column: 1;
            grid-row: 1/3;
        }

        .name {
            grid-column: 2;
        }
    }

    &.avatar-right {
        grid-template-columns: 3fr auto;



        .avatar {
            grid-column: 2;
            grid-row: 1/3;
        }

        .name {
            grid-column: 1;
            text-align: right;
        }

        .message-box {
            text-align: right;
        }
    }



    &.inlive {
        gap: .5em 5px;

        .name {
            padding-top: .5em;
        }

        .avatar {

            justify-content: flex-start;

            img {
                width: 150px;
            }
        }

        &.avatar-left {
            .message-box {
                border-radius: 0 1em 1em 1em;
            }
        }

        &.avatar-right {
            .message-box {
                border-radius: 1em 0 1em 1em;
            }
        }
    }


`;

export default function DialogPhase({ phase }: any) {
    const classes = classNames([phase.style, phase.place, 'avatar-' + phase.avatarPosition])

    return <Container className={classes}>
        <div className="name"><span>{phase.name}</span></div>
        <div className="avatar"><img src={phase.avatar} alt={phase.name} /></div>
        <div className="message">
            <div className="message-box">
                <span dangerouslySetInnerHTML={{__html: parseInlineTags(clearBlockTags(phase.text))}}></span>
            </div>

        </div>
    </Container>
}
