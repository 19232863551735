import styled from "styled-components"
import { clearBlockTags } from "./rich-text";

const Container = styled.div`
    margin: .5em 0;

    &.action {


        padding: .5em 0 0 2em;

        button {
            background: var(--action-main-color);
            border: none;
            color: white;
            font-family: 'Exo', sans-serif;
            font-weight: 500;
            font-size: 1rem;

            @media (min-width: 768px) {
                font-size: .85rem;
            }

            position: relative;

            min-height: 3.4em;

            border-radius: 1.2em;

            padding: .2em 2em .2em 3em;

            width: 100%;

            text-align: left;


            cursor: pointer;

            &:before {
                position: absolute;
                top: -.0em;
                left: -1em;

                content: '?';

                font-family: var(--audiowide);
                font-size: 1.5em;
                color: white;

                width: 2em;
                height: 2em;

                border-radius: 50%;
                border: .15em solid white;
                box-shadow: 0 0 0 .3em var(--action-main-color);

                background: var(--action-inside-color);

                display: flex;
                justify-content: center;
                align-items: center;

            }

            &:hover {
                background: var(--action-hover-color);

                &:before {
                    box-shadow: 0 0 0 .3em var(--action-hover-color);
                }
            }

            span.text-wrapper {
                width: 100%;
                overflow: hidden;
                display: inline-block;
            }

            span.text-inner-wrapper {
                width: 112%;
                display: inline-block;
                transform-origin: 0 50%;
                transform: scale(.89, 1)
            }
        }
    }

    &.next {

        display: flex;
        justify-content: center;

        button {
            font-size: 1em;
            text-decoration: none;
            padding: 1.1em 5em;
            border-radius: 1.6em;
            border: none;
            background: var(--action-main-color);
            color: white;
            text-transform: uppercase;

            display: block;

            box-shadow: 0 .18em .15em 0 rgba(53,73,94, .45);

            font-weight: 600;

            &:hover {
                cursor: pointer;
                background: var(--action-hover-color);
            }
        }
    }

`;

export default function ActionPhase({ phase, sendAction }: any) {
    return <Container className={`${phase.choiceType}`}>
        <button onClick={() => sendAction(phase.id)}>
            <span className="text-wrapper">
                <span className="text-inner-wrapper">{clearBlockTags(phase.text || 'Dalej')}</span>
            </span>
        </button>
    </Container>
}
