import { useCallback } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import useCreateNemGame from "../../modules/game-master/hooks/use-new-game";
import useGMScenarios from "../../modules/game-master/hooks/use-scenarios";
import Loader from "../loader";

const Container = styled.div`
    width: 100%;
    font-size: 16pt;

    padding: 1em;
    border-radius: 1em;

    background: var(--color1);

    .loader {
        position: relative;

        width: 100%;
        height: 50vh;
        margin: 2em;
    }

    .field {
        margin: .5em 0;
        padding: .5em 0;
        border-bottom: 1px solid var(--color2);
    }

    textarea {
        width: 100%;
        height: 7em;
    }

    .small {
        font-size: .5em;
    }

    .buttons {
        margin: 1em 0 0;
        display: flex;
        justify-content: center;
    }

    .button {
        text-decoration: none;
        padding: 1em 2em;
        border-radius: 1.5em;
        border: none;
        background: var(--color3);
        color: white;
        text-transform: uppercase;
        font-size: .8em;
        font-weight: normal;

        display: inline-block;

        &:hover {
            background: var(--color2);
        }
    }
`;

export default function NewGameForm() {
    const { handleSubmit, register } = useForm();

    const { isLoading: newGameLoading, createNewGame } = useCreateNemGame();
    const { isLoading: scenariosLoading, scenarios } = useGMScenarios();

    const isLoading = newGameLoading || scenariosLoading;

    const showLoader = isLoading;
    const showForm = !showLoader;

    const onSubmit = useCallback((data) => {
        console.log(data);

        let playerCount = parseInt(data.playerCount);

        createNewGame({
            scenario: data.scenario,
            description: data.description,
            playerCount: isNaN(playerCount) ? 0 : playerCount
        });
    }, [createNewGame]);


    return <Container>
        {isLoading && <div className="loader">
            <Loader />
        </div>}
        {showForm && <form onSubmit={handleSubmit(onSubmit)}>
            <div className='field'>
                <label>
                    Scenariusz: <select {...register('scenario')}>
                        {
                            (scenarios || []).map((s: any) => {
                                return <option key={s._id} value={s._id}>{s.name}</option>
                            })
                        }
                    </select>
                </label>
            </div>
            <div className='field'>
                <label>
                    Opis:<br />
                    <textarea {...register('description')}></textarea>
                </label>
            </div>
            <div className='field'>
                <label>
                    Wstępna ilość graczy:<br />
                    <span className="small">(max 50. później będzie można dodać nowych graczy)</span><br />
                    <input type="number" {...register('playerCount')} min={0} max={10000} defaultValue={35} />
                </label>
            </div>

            <div className="buttons">
                <button className="button">Załóż grę</button>
            </div>
        </form>}
    </Container>
}
