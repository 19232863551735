import { UserModuleState, UserModuleAction, USER_MODULE_ACTION, initUserModuleState } from ".";

export default function UserReducer(state: UserModuleState, action: UserModuleAction): UserModuleState {
    const {type, payload} = action;
    switch (type) {
        case USER_MODULE_ACTION.SET_USER_ID:
            return {
                ...state,
                userId: payload.userId
            }

        case USER_MODULE_ACTION.RESET_USER_ID: {
            return {
                ...initUserModuleState
            }
        }
    }

    return state;
}
