import styled from "styled-components";
// eslint-disable-next-line
import cyberCircle from '!file-loader!../../assets/cyber-circle.svg';
import fromHeader from '../../assets/form-header.png';
import { useCallback, useState } from "react";
import classNames from "classnames";

const Container = styled.div`

    margin: 2em 0;

    background: url(${fromHeader}) 50% 2em #cde9fc no-repeat;
    background-size: 100% auto;
    padding: 2em;
    border-radius: 2em;

    padding-top: 30%;

    position: relative;

    h3 {
        font-size: 1.5em;
        text-align: center;
    }

    strong {
        font-weight: inherit;
        color: #3498db;
    }

    p {
        margin: 1em;
        text-align: center;
    }

    .buttons {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .button-wrapper {
            position: relative;
            display: inline-block;

            padding: 3em 0;

            z-index: 1;

    }

    button {
        font-size: 1em;
        text-decoration: none;
        padding: 1.1em 5em;
        border-radius: 1.6em;
        border: none;
        background: var(--action-main-color);
        color: white;
        text-transform: uppercase;

        display: block;

        box-shadow: 0 .18em .15em 0 rgba(53,73,94, .45);

        font-weight: 600;

        &:hover {
            cursor: pointer;
            background: var(--action-hover-color);
        }

    }

    .sended-info {

        position: absolute;

        justify-content: center;
        align-items: center;

        p {
            font-family: var(--audiowide);
            text-transform: uppercase;
            font-size: 2em;
            font-weight: 300;
            color: var(--color3);
        }
    }

    &.pre {
        .button-wrapper {

            &:after {
                content: '';
                z-index: -1;

                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;

                background: url(${cyberCircle}) 50% 50% no-repeat;
                background-size: contain;
                transform:rotate(120deg);
            }
        }

        .sended-info {
            display: none;
        }
    }

    &.sending {
        .button-wrapper {
            &:after {
                content: '';
                z-index: -1;

                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;

                background: url(${cyberCircle}) 50% 50% no-repeat;
                background-size: contain;
                animation:spin 2s linear infinite;


                @keyframes spin {
                    100% {
                        transform:rotate(360deg);
                    }
                }
            }

            button {
                opacity: 0;
                pointer-events: none;
            }
        }

        .sended-info {
            display: none;
        }
    }

    &.post {
        .button-wrapper {
            opacity: 0;
        }

        .sended-info {
            display: flex;
        }
    }
`;

type Props = {
    gameForm: string,
    gameSubject: string,
    gameGoal: string,
    onComplete(): void
}

export default function RegisterFormPhase({ gameForm, gameSubject, gameGoal, onComplete }: Props) {

    const [state, setSended] = useState('pre');

    const send = useCallback(() => {
        setSended('sending');

        setTimeout(() => {
            setSended('post');
            onComplete();
        }, 1000);

    }, [setSended, onComplete]);

    return <Container className={classNames(state)}>
        <div className="form">
            <h3>Zgłaszamy się do udziału w konkursie.</h3>
            <p>Nasz projekt to gra <strong>{gameForm}</strong></p>
            <p>dotycząca <strong>{gameSubject}</strong></p>
            <p>której celem będzie <strong>{gameGoal}</strong></p>
            <div className="buttons">
                <span className="button-wrapper">
                    <div className="button-position">
                        <button onClick={send}>Wyślij</button>
                    </div>
                </span>
                <div className="sended-info">
                    <p>Wysłane!</p>
                </div>
            </div>
        </div>
    </Container>
}
