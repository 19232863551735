import { useCallback, useMemo, useState } from "react";
import styled from "styled-components"

// eslint-disable-next-line
import okIcon from "!file-loader!../../assets/icons/v-biala.svg";


// eslint-disable-next-line
import arrowIcon from "!file-loader!../../assets/icons/arrow.svg";
// eslint-disable-next-line
import arrow2Icon from "!file-loader!../../assets/icons/arrow2.svg";
import CoverCanvas, { BACKGROUNDS, OBJECTS, TITLES } from "./cover-canvas";

type Props = {
    eco: number,
    edu: number,
    gra: number,

    onConfirm(bgIndex: number, objIndex: number, titleIndex: number): void
}

const Container = styled.div`
    position: relative;
    min-height: 100vh;

    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;

    .cover {
        max-width: 100vw;
        max-height: 100vh;

        width: 100vw;

        aspect-ratio: 9/16;
        margin: 0 auto;

        position: relative;
    }

    .canvas-container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;

        canvas {
            display: block;
            object-fit: contain;
            width: 100%;
        }
    }

    .confirm-overlay {
        position: relative;
        width: 100%;
        height: calc(100% + 5vh);
        max-height: 99vh;
    }

    .confirm {
        position: absolute;
        bottom: 0vh;
        left: 50%;

        display: flex;
        justify-content: center;


        .wrapper {
            overflow: visible;
            transform: translate(-50%, 0);
            padding: 0;
        }

        .effect {
            padding: 3px;
            border-radius: 50%;

            animation: pulse-white 2s infinite;
        }

        img {
            cursor: pointer;
            display: block;

            height: 10vh;
        }
    }

    .controlls {
        pointer-events: none;

        position: absolute;
        top: 0;
        left: 0;
        rigth: 0;
        bottom: 0;

        width: 100%;

        // aspect-ratio: 9/16;

        overflow: hidden;


        color: white;

        .download {
            display: flex;
            justify-content: center;
        }

        .arrow {
            pointer-events: all;

            position: absolute;
            display: flex;
            justify-content: center;
            font-size: 3vh;
            background: rgba(52,152,219,.6);

            box-shadow: 0 2px 3px 0 rgba(0,0,0,.5);

            cursor: pointer;

            img {
                max-height: 1em;
            }
        }

        .prev-arrow {
            left: 0;
            border-radius: 0 2em 2em 0;
            overflow: hidden;
            padding: .5em .7em .5em .4em;

            img {
                filter: drop-shadow(2px 2px 1px rgb(0 0 0 / 0.4));
            }
        }

        .next-arrow {
            right: 0;
            border-radius: 2em 0 0 2em;
            overflow: hidden;
            padding: .5em .4em .5em .7em;

            img {
                filter: drop-shadow(2px 2px 1px rgb(0 0 0 / 0.4));
            }
        }

        .bg-arrow {
            top: 81%;
        }

        .object-arrow {
            top: 46.5%;
        }

        .title-arrow {
            top: 19%;
        }
    }

    @keyframes pulse-white {
        0% {
          transform: scale(0.95);
          box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
        }

        70% {
          transform: scale(1);
          box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
        }

        100% {
          transform: scale(0.95);
          box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
        }
      }

    @media (min-aspect-ratio: 9/16) {
        .cover {
            width: auto;
            height: 100vh;
        }
    }
`;

export default function CoverGenerator({ eco, edu, gra, onConfirm }: Props) {

    const backgrounds = useMemo(() => {
        return BACKGROUNDS.filter(b => {
            return ((b.eco || 0) <= eco) &&
                ((b.edu || 0) <= edu) &&
                ((b.gra || 0) <= gra);
        })
    }, [eco, edu, gra]);

    const objects = useMemo(() => {
        return OBJECTS.filter(b => {
            return ((b.eco || 0) <= eco) &&
                ((b.edu || 0) <= edu) &&
                ((b.gra || 0) <= gra);
        })
    }, [eco, edu, gra]);

    const titles = useMemo(() => {
        return TITLES.filter(b => {
            return ((b.eco || 0) <= eco) &&
                ((b.edu || 0) <= edu) &&
                ((b.gra || 0) <= gra);
        })
    }, [eco, edu, gra]);

    const [bgIndex, setBg] = useState(0);
    const [objectIndex, setObject] = useState(0);
    const [titleIndex, setTitle] = useState(0);

    const confirm = useCallback(() => {
        onConfirm(bgIndex, objectIndex, titleIndex);
    }, [onConfirm, bgIndex, objectIndex, titleIndex]);

    return <Container>
        <div className="cover">
            <div className="canvas-container">
                <CoverCanvas bgIndex={bgIndex} objIndex={objectIndex} titleIndex={titleIndex} />
            </div>
            <div className="confirm-overlay">
                <div className="confirm">
                    <div className="wrapper">
                        <div className="effect">
                            <img alt="" src={okIcon} onClick={confirm} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="controlls">
                <div className="bg-prev arrow bg-arrow prev-arrow" onClick={() => setBg(v => (v + backgrounds.length - 1) % backgrounds.length)}>
                    <img alt="" src={arrow2Icon} />
                </div>
                <div className="bg-next arrow bg-arrow next-arrow" onClick={() => setBg(v => (v + 1) % backgrounds.length)}>
                    <img alt="" src={arrowIcon} />
                </div>

                <div className="object-prev arrow object-arrow prev-arrow" onClick={() => setObject(v => (v + objects.length - 1) % objects.length)} >
                    <img alt="" src={arrow2Icon}/>
                </div>
                <div className="object-next arrow object-arrow next-arrow" onClick={() => setObject(v => (v + 1) % objects.length)}>
                    <img alt="" src={arrowIcon} />
                </div>

                <div className="title-prev arrow title-arrow prev-arrow" onClick={() => setTitle(v => (v + titles.length - 1) % titles.length)}>
                    <img alt="" src={arrow2Icon} />
                </div>
                <div className="title-next arrow title-arrow next-arrow" onClick={() => setTitle(v => (v + 1) % titles.length)}>
                    <img alt="" src={arrowIcon} />
                </div>
            </div>
        </div>
    </Container>
}
