import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router";
import Loader from "../../components/loader";
import AdminNetwork from "../../modules/network/admin";

export default function SurveyRaportPage() {
    const {surveyId} = useParams<{surveyId: string}>();

    const {data, error, isLoading} = useQuery(['survay-raport', surveyId], () => AdminNetwork.getSurveyRaport(surveyId || ''));


    return <>
    {!isLoading && <SurveyRaport data={data} />}
    {isLoading && <Loader />}
    {error && <p>Coś poszło nie tak...</p>}
    </>
}

function SurveyRaport(data: any) {
    return <pre>
        {JSON.stringify(data, null, 2)}
    </pre>
}
