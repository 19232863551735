import classNames from "classnames";
import styled from "styled-components";
import useResetOnCheckpoint from "../../modules/game/hooks/use-reset-on-checkoint";
import useLogout from "../../modules/user/hooks/use-logout";
import Loader from "../loader";
import Checkpoints from "./checkpoints";
import GameInfo from "./game-info";

type Props = {
    user: any
}

const Container = styled.div`

    h2 {
        text-align: center;
    }

    .player-info {
        background: var(--color3);
        padding: .5em 1em;
        border-radius: 1em;
    }

    .logged {
        padding: .5em 0;
        border-bottom: 1px white solid;

        display: flex;
        justify-content: space-between;
    }

    .logout {
        border: 3px solid white;
        padding: .1em 1em;
        border-radius: 1em;
        background: white;
        cursor: pointer;

        &:hover {
            border-color: red;
        }
    }

    .button.reset {
        text-decoration: none;
        padding: 1em 2em;
        border-radius: 1.5em;
        border: none;
        background: var(--colorError1);
        color: white;
        text-transform: uppercase;

        display: inline-block;

        cursor: pointer;

        &:hover {
            color: black;
            background: var(--colorError2);
        }
    }

    .buttons-reset {
        width: 100%;
        display: flex;
        justify-content: center;

        margin: 1em 0;

        position: relative;

        .loader {
            display: none;

            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            z-index: 10;
        }

        &.show-loader {
            .loader {
                display: block;
            }

            button {
                opacity: 0;
                pointer-events: none;
            }
        }
    }
`;

export default function UserProfile({ user }: Props) {

    const showCheckpoints = Boolean(user.checkpoints?.chapter1);

    const { reset, isLoading } = useResetOnCheckpoint();

    const { logout } = useLogout();

    const resetButtonClasses = classNames({
        "buttons-reset": true,
        "show-loader": isLoading
    })

    console.log(user);

    return <Container>
        <h2>Witamy w grze</h2>
        <div className="player-info">
            {!user.openPlayer &&
            <div className="logged">
                <div className="name">
                    Zalogowany gracz: <strong>{user.token}</strong>
                </div>
                <button className="logout" onClick={_ => logout()}>Wyloguj</button>
            </div>}
            <GameInfo cover={user.cover} gameStatus={user.gameStatus} score={user.score || {}} endingType={user.endingType} chapter={user.chapter} />
        </div>

        {
            showCheckpoints && <>
                <h3>Stan gry z poprzednich rozdziałów</h3>
                <Checkpoints checkpoints={user.checkpoints} />
            </>
        }
        {!showCheckpoints && <div className={resetButtonClasses}>
            <div className="loader">
                <Loader />
            </div>
            <button className="button reset" onClick={_ => reset()}>Zagraj od początku</button>
        </div>
        }
    </Container >
}

