

export type SurveyData = {
    id: string,
    questions: SurveyQuestion[]
}

export type SurveyQuestion = {
    id: string,
    type: SurveyQuestionType,
    label: string,
}

export enum SurveyQuestionType {
    text,
    bigText,
    grade,
    trueFalse
}

export const SURVEY_LIST: {[key: string]: SurveyData} = {
    'player': {
        id: 'survey-player',
        questions: [
            {
                id: 'q1',
                label: 'Graczu! Napisz coś małego',
                type: SurveyQuestionType.text
            },
            {
                id: 'q2',
                label: 'Napisz coś dużego Graczu! ',
                type: SurveyQuestionType.bigText
            },
            {
                id: 'q3',
                label: 'Graczu? Jak bardzo nie podoba ci ise ta gra?',
                type: SurveyQuestionType.grade
            },
            {
                id: 'q4',
                label: 'Przeczytałe/aś inne pytania?',
                type: SurveyQuestionType.trueFalse
            }
        ]
    },
    'tester': {
        id: 'survey-tester',
        questions: [
            {
                id: 'q1',
                label: 'E! Tester! Napisz coś małego',
                type: SurveyQuestionType.text
            },
            {
                id: 'q2',
                label: 'Napisz coś dużego. Proszę....',
                type: SurveyQuestionType.bigText
            },
            {
                id: 'q3',
                label: 'W poniższej skali powiedź jak bardzo jest źle?',
                type: SurveyQuestionType.grade
            },
            {
                id: 'q4',
                label: 'Przeczytałe/aś inne pytania? Na pewno?',
                type: SurveyQuestionType.trueFalse
            }
        ]
    }
}
