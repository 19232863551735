import styled from "styled-components";
// eslint-disable-next-line
import cyberCircle from '!file-loader!../../../assets/icons/next.svg';

type Props = {
    speaker: 'narrator' | 'npc' | 'hero',
    name?: string,
    onNext?(): void
}

const Container = styled.div<{ noBackground: boolean, maxHeight: boolean }>`
    display: flex;
    align-items: stretch;
    padding: 0 2em 0 3em;
    width: calc(100% - 5em);

    .box-content {
        position: relative;

        width: 100%;
        padding: 1.5em 2em 1em;

        border-radius: 0 1em 1em 1em;
    }

    .box-text-content {
        @media(orientation: landscape) and (min-height: 800px) {
            max-height: 33vh;

            overflow-y: auto;
            overflow-x: hidden;

            padding: .1em 0
        }

        &>*:first-child {
            margin-top: 0;
        }

        &>*:last-child {
            margin-bottom: 0;
        }
    }

    .name {
        position: absolute;
        top: -1em;
        left: -1em;

        padding: .5em 1em;
        height: 1em;
        min-width: 6em;

        border-radius: 1.5em;

        text-align: center;

        transform-origin: 0;
        transform: scale(.89,1);

        font-weight: 700;
    }

    .next-button {

        position: absolute;

        bottom: -1.5em;
        right: -1em;

        border: none;

        color: white;

        padding: 1em 2.5em 1em 3em;
        border-radius: 3em;
        cursor: pointer;

        height: 1.5em;

    }



    div.text {
        margin: 1em 0;
    }

    &.hero {
        .name {
            color: black;
            background: #91cff8;
        }

        .dialog {
            color: #333333;

            i {
                font-weight: 400;
            }

            width: 112%;
            transform: scale(.89,1);
            transform-origin: 0;
        }

        .box-content {
            background: #daeaf4;
        }

        .next-button {
            background: #3498db;
        }
    }

    &.npc {
        .name {
            color: black;
            background: #d9d7d7;
        }

        .dialog {
            color: #333333;

            i {
                font-weight: 400;
            }

            width: 112%;
            transform: scale(.89,1);
            transform-origin: 0;
        }

        .box-content {
            background: #edeef4;
        }

        .next-button {
            background: #3498db;
        }
    }

    &.narrator {
        .name {
            display: none;
        }

        .dialog {
            color: #333333;

            font-weight: 400;
            font-style: italic;

            i {
                font-weight: 400;
            }

            width: 112%;
            transform: scale(.89,1);
            transform-origin: 0;
        }

        .box-content {
            background: #edeef4;
        }

        .next-button {
            background: #3498db;
        }
    }

`;

export default function TextBox({ name, onNext, children, speaker }: React.PropsWithChildren<Props>) {

    const showName = Boolean(name);

    return <Container className={speaker} noBackground={false} maxHeight={false}>
        <div className="box-content">
            {showName && <div className="name"><span>{name}</span></div>}
            <div className="box-text-content">
                {children}
            </div>
            {onNext && <input type="image" src={cyberCircle} className="next-button" alt="dalej" onClick={onNext}></input>}
        </div>
    </Container>
}
