import { useCallback, useEffect, useMemo } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { ChapterScreenParams } from '../../modules/game/hooks/use-game-step.types';

import chapterEkoImgSrc from '../../assets/chapters/chapter-eko.png';
import chapterEduImgSrc from '../../assets/chapters/chapter-edu.png';
import chapterGraImgSrc from '../../assets/chapters/chapter-gra.png';
import useSendAction from "../../modules/game/hooks/use-send-action";
import Loader from "../loader";
import classNames from "classnames";

const Container = styled.div<{ bgImage: string, actionColor: string, actionColorHover: string }>`

    width: 98%;
    max-width: 800px;
    margin: 0 auto;

    height: 100vh;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1em 1fr 150px 1em;

    .image {
        grid-row: 2;
        display: block;
        background: url(${props => props.bgImage}) no-repeat 50% 0;
        background-size: contain;
    }

    .button-container {
        grid-row: 3;
        display: flex;
        justify-content: center;
        align-items: center;

        position: relative;

        .loader {
            display: none;

            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            z-index: 10;
        }

        &.show-loader {
            .loader {
                display: block;
            }

            button {
                opacity: 0;
                pointer-events: none;
            }
        }
    }

    button {
        font-size: 1em;
        text-decoration: none;
        padding: 1.1em 5em;
        border-radius: 1.6em;
        border: none;
        background: ${props => props.actionColor};
        color: white;
        text-transform: uppercase;

        display: block;

        box-shadow: 0 .18em .15em 0 rgba(53,73,94, .45);

        font-weight: 600;

        &:hover {
            cursor: pointer;
            background: ${props => props.actionColorHover};
        }
    }
`;

const PageBackgroud = createGlobalStyle<{ bgColor: string }>`
    body {
        background: ${props => props.bgColor} !important;
    }

    :root {
        --step-bg-color: ${props => props.bgColor} !important;
    }
`;


export default function ChapterTitle({ screen }: { screen: ChapterScreenParams }) {
    const { send, isLoading } = useSendAction();

    const sendAction = useCallback((id: string) => {
        console.log('SEND: ', id);
        send(id);
    }, [send]);

    useEffect(() => {
        if (![1, 2, 3].includes(screen.index)) {
            sendAction(screen.id);
        }
    }, [screen, sendAction]);

    const screenInfo = useMemo(() => {
        switch (screen.index) {
            case 1:
                return {
                    bgColor: '#61b577',
                    bgImage: chapterEkoImgSrc,
                    actionColor: '#0F4A4E',
                    actionColorHover: '#30D6A0'
                }

            case 2:
                return {
                    bgColor: '#e3c23d',
                    bgImage: chapterEduImgSrc,
                    actionColor: '#CE6E18',
                    actionColorHover: '#FFA31B'
                }

            case 3:
                return {
                    bgColor: '#b9a2e1',
                    bgImage: chapterGraImgSrc,
                    actionColor: '#6237A0',
                    actionColorHover: '#BC95FF'
                }

            default:
                return {
                    bgColor: '',
                    bgImage: '',
                    actionColor: '',
                    actionColorHover: '',
                }
        }
    }, [screen]);

    const buttonsClass = classNames({
        "button-container": true,
        "show-loader": isLoading
    })

    return <Container {...screenInfo}>
        <PageBackgroud bgColor={screenInfo?.bgColor} />
        <div className="image"></div>
        <div className={buttonsClass}>
            <div className="loader">
                <Loader />
            </div>
            <button onClick={() => sendAction(screen.id)}>
                Dalej
            </button>
        </div>
    </Container>
}
