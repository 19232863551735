import classNames from "classnames";
import { PropsWithChildren } from "react";
import styled from "styled-components";

import iconEdu from '../../assets/icons/edu-02.svg';
import iconEco from '../../assets/icons/eko-02.svg';
import iconGra from '../../assets/icons/gra-02.svg';

const Container = styled.div`
`;

const ScoreContainer = styled.div`
    display: grid;

    grid-template-columns: 6em 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 10px;

    margin: 1em 0;

    .icon {
        grid-column: 1;
        grid-row: 1/4;

        img {
            display: block;
            /* object-fit: contain; */

            max-width: 6em;
        }
    }

    .label {
        grid-column: 2;
        grid-row: 1;

        font-size: 1.5em;
        font-weight: 700;
        align-self: center;

        transform: scale(.89, 1);
        transform-origin: 0%;
    }

    .score {
        grid-column: 3;
        grid-row: 1;

        font-size: 1.5em;
        font-weight: 700;
        align-self: center;
        justify-self: end;

        transform: scale(.89, 1);
        transform-origin: 100%;
    }

    .score-bar-container {
        grid-column: 2/4;
        grid-row: 2;
        align-self: center;
    }

    &.eco {
        .label,
        .score {
            color: #0F4A4E;
        }
    }

    &.edu {
        .label,
        .score {
            color: #ce6e18;
        }
    }

    &.gra {
        .label,
        .score {
            color: #6237A0;
        }
    }
`;

export default function ChapterScores({children}: PropsWithChildren<{}>) {
    return <Container>
        {children}
    </Container>
}


export function ChapterScore({ label, value, max, type }: { label: string, value: number, max: number, type: string }) {

    const className = classNames(['score', type])

    return <ScoreContainer className={className}>
        <div className="icon">
            {type === 'eco' && <img src={iconEco} alt="punkty EKO" />}
            {type === 'edu' && <img src={iconEdu} alt="punkty EDU" />}
            {type === 'gra' && <img src={iconGra} alt="punkty GRA" />}
        </div>
        <div className="label">{label}</div>
        <div className="score">{value} / {max}</div>
        <div className="score-bar-container">
            <ScoreBar value={value / max} scoreType={type} />
        </div>
    </ScoreContainer>
}


const ScoreBar = styled.div<{ value: number, scoreType: string }>`
    display: block;
    background: #F1F1F1;
    position: relative;

    width: 100%;
    height: .5em;

    border-radius: .25em;
    border: 3px solid #F1F1F1;

    &:after {
        content: '';
        position: absolute;

        top: 0;
        left: 0;
        bottom: 0;
        border-radius: .25em;
        width: ${(props) => props.value * 100}%;

        background: red;

        ${({ scoreType }) => {
        switch (scoreType) {
            case 'eco':
                return 'background: #63B479; box-shadow: 0 0 3px  #63B479;'

            case 'edu':
                return 'background: #CE6E18; box-shadow: 0 0 3px  #CE6E18;'

            case 'gra':
                return 'background: #6237A1; box-shadow: 0 0 3px  #6237A1;'
        }

        return '';
    }}

        border-radius: .25em;
    }
`;
