import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import Network from "../../network";
import { UseGameStepResult } from "./use-game-step.types";





export default function useGameStep(): UseGameStepResult {
    const { data, isLoading, error } = useQuery(['game-step'], () => Network.getGameStep(), {
        staleTime: 1000 * 60
    });
    const navigate = useNavigate();

    useEffect(() => {
        if (error) {
            console.log('ERROR!!!!!!!!!', error)
            // navigate(getPlayerIntroRoute());
        }
    }, [error, navigate]);

    return {
        step: data,
        isLoading,
        error,
        showChapterTitle: Boolean(data?.step.chapterTitleScreen),
        chapterTitleParams: data?.step.chapterTitleScreen,
        showChapterEnding: Boolean(data?.step.chapterEnd),
        chapterEndingParams: data?.step.chapterEnd
    }
}
