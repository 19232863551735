import { useMemo } from "react";
import styled from "styled-components";
import useResetOnCheckpoint from "../../../modules/game/hooks/use-reset-on-checkoint";
import { useUser } from "../../../modules/user/hooks/use-user";
import ChapterScores, { ChapterScore } from "../../chapter-score";
import ScrollToTop from "../../utils/scroll-to-top";

type Props = {
    testerSurvey: boolean,
    onNext(): void,
    chapterIndex: number
}

const Container = styled.div`

    width: 95%;
    margin: 0 auto;

    h2 {
        text-align: center;
        transform: scale(.89, 1);
    }

    p {
        width: 112%;
        text-align: center;
        transform: scale(.89, 1);
        transform-origin: 0;
    }

    .bigger {
        font-size: 1.5em;
    }

    .wrapper {
        overflow: hidden;

        display: flex;
        flex-direction: column;

        padding: 2em 2em 1em;
    }

    .scores {
        margin: 0 0 1em 0;
    }

    .points-change {
        margin: 0 0 1em 0;
    }

    .buttons {
        margin: 2em;

        display:flex;
        flex-direction: column;
        justify-content: space-around;

        position: relative;

        .loader {
            display: none;

            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            z-index: 10;
        }

        &.show-loader {
            .loader {
                display: block;
            }

            button, a {
                opacity: 0;
                pointer-events: none;
            }
        }
    }

    .button {
        text-decoration: none;
        padding: 1em 2em;
        border-radius: 1.5em;
        border: none;
        background: var(--action-main-color);
        border: 2px solid var(--action-main-color);
        color: white;
        text-transform: uppercase;
        font-size: 1rem;
        font-weight: 600;
        box-shadow: 0 3px 3px 1px #d9d9d9;

        margin: .5em 0;

        cursor: pointer;

        display: inline-block;

        &:hover {
            background: var(--action-hover-color);
            border-color: var(--action-hover-color);
        }


        &.inverted {
            background: white;
            border-color: var(--action-main-color);
            color: var(--action-main-color);

            &:hover {
                background: var(--action-hover-color);
                border-color: var(--action-hover-color);
                color: white;
            }
        }
    }

    .tester-survey {
        margin: 1em 0;
        padding: .5em;
        border: 3px solid var(--colorError1);
        text-align: center;
    }
`;

export default function ChapterScoresScreen({ testerSurvey, onNext, chapterIndex }: Props) {
    const { data, isLoading: userDataLoading } = useUser();
    const { reset } = useResetOnCheckpoint(chapterIndex);

    console.log(data);

    const oldPoints: { eco: number, edu: number, gra: number } = useMemo(() => {
        switch (chapterIndex) {
            case 2:
                return {
                    eco: data?.checkpoints?.chapter1?.globals?.Punkty___eco || 0,
                    edu: data?.checkpoints?.chapter1?.globals?.Punkty___edu || 0,
                    gra: data?.checkpoints?.chapter1?.globals?.Punkty___gra || 0,
                }
            case 3:
                return {
                    eco: data?.checkpoints?.chapter2?.globals?.Punkty___eco || 0,
                    edu: data?.checkpoints?.chapter2?.globals?.Punkty___edu || 0,
                    gra: data?.checkpoints?.chapter2?.globals?.Punkty___gra || 0,
                }
            case 4:
                return {
                    eco: data?.checkpoints?.chapter3?.globals?.Punkty___eco || 0,
                    edu: data?.checkpoints?.chapter3?.globals?.Punkty___edu || 0,
                    gra: data?.checkpoints?.chapter3?.globals?.Punkty___gra || 0,
                }
            case 5:
                return {
                    eco: data?.checkpoints?.chapter4?.globals?.Punkty___eco || 0,
                    edu: data?.checkpoints?.chapter4?.globals?.Punkty___edu || 0,
                    gra: data?.checkpoints?.chapter4?.globals?.Punkty___gra || 0,
                }
        }

        return {
            eco: 0,
            edu: 0,
            gra: 0
        }
    }, [data, chapterIndex]);

    const eco = data?.score?.eco || 0;
    const edu = data?.score?.edu || 0;
    const gra = data?.score?.gra || 0;

    const ecoChange = eco - oldPoints.eco;
    const eduChange = edu - oldPoints.edu;
    const graChange = gra - oldPoints.gra;

    const maxPoints = chapterIndex === 1 ? 9 : 18;

    return <Container>
        <div className="wrapper">
            <ScrollToTop behavior='smooth' />
            {chapterIndex === 1 && <p>To koniec pierwszego rozdziału gry. Już niedługo będziecie mogli poznać dalsze losy przyjaciół z grupy Latający Talerz. Punkty zdobyte w tym rozdziale pomogą Wam w kolejnych.</p>}
            {chapterIndex === 2 && <p>To koniec drugiego rozdziału gry. Już niedługo będziecie mogli poznać dalsze losy przyjaciół z grupy Latający Talerz. Punkty zdobyte w tym rozdziale dadzą Wam więcej możliwości przy tworzeniu Waszej aplikacji.</p>}
            {chapterIndex === 3 && <p>To koniec trzeciego rozdziału gry. Już niedługo będziecie mogli poznać dalsze losy przyjaciół z grupy Latający Talerz. Punkty zdobyte w tym rozdziale dadzą Wam więcej możliwości przy tworzeniu Waszej aplikacji.</p>}
            {chapterIndex === 4 && <p>To koniec czwartego rozdziału gry. Już niedługo będziecie mogli poznać dalsze losy przyjaciół z grupy Latający Talerz. Punkty zdobyte w tym rozdziale dadzą Wam więcej możliwości przy tworzeniu Waszej aplikacji.</p>}
            <h2>Wynik</h2>
            {testerSurvey && <div className="tester-survey">
                <a href="https://docs.google.com/forms/d/e/1FAIpQLSerCkgurCqwoqmny8yyyaqfUSKHqCqN5Ait-8Q_PIvX6WLt5w/viewform" target="_blank" rel="noreferrer"> Prosimy wypełnić ankietę {'>>'} </a>
            </div>}
            {!userDataLoading && <div className="scores">
                <ChapterScores>
                    {chapterIndex === 1 && <>
                        <ChapterScore label={"Eko"} value={eco} max={maxPoints} type={"eco"}></ChapterScore>
                        <ChapterScore label={"Edu"} value={edu} max={maxPoints} type={"edu"}></ChapterScore>
                        <ChapterScore label={"Gra"} value={gra} max={maxPoints} type={"gra"}></ChapterScore>
                    </>}
                    {chapterIndex === 2 && <>
                        <ChapterScore label={"Eko"} value={eco} max={18} type={"eco"}></ChapterScore>
                    </>}
                    {chapterIndex === 3 && <>
                        <ChapterScore label={"Edu"} value={edu} max={18} type={"edu"}></ChapterScore>
                    </>}
                    {chapterIndex === 4 && <>
                        <ChapterScore label={"Gra"} value={gra} max={18} type={"gra"}></ChapterScore>
                    </>}
                    {chapterIndex === 5 && <>
                        <ChapterScore label={"Eko"} value={eco} max={18} type={"eco"}></ChapterScore>
                        <ChapterScore label={"Edu"} value={edu} max={18} type={"edu"}></ChapterScore>
                        <ChapterScore label={"Gra"} value={gra} max={18} type={"gra"}></ChapterScore>
                    </>}
                </ChapterScores>
            </div>
            }
            {(chapterIndex !== 1) && <div className="points-change">
                {Boolean(ecoChange) && <p className="bigger">
                    W tym rozdziale udało Ci się zdobyć {ecoChange} pkt Eko.
                </p>}
                {Boolean(eduChange) && <p className="bigger">
                    W tym rozdziale udało Ci się zdobyć {eduChange} pkt Edu.
                </p>}
                {Boolean(graChange) && <p className="bigger">
                    W tym rozdziale udało Ci się zdobyć {graChange} pkt Gra.
                </p>}
            </div>}
            <p className="bigger">Czy chcesz spróbować ponownie, by zmienić otrzymany wynik?</p>
            <div className="buttons">
                <button className="button inverted" onClick={() => reset()}>
                    Tak, chcę zmienić swój wynik
                </button>
                <button className="button" onClick={onNext}>
                    Chcę zagrać w następny rozdział
                </button>
            </div>
        </div>

    </Container>
}
