import styled from "styled-components"
import { clearBlockTags } from "./rich-text";

const Container = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;

    img {
        max-width: 100%;
        max-height: 66vh;
    }

    h2 {
        text-align: center;

        width: 112%;
        transform-origin: center;
        transform: scale(.89,1);
    }
`;

export default function HeaderPhase({ phase }: any) {
    return <Container>
        {phase.image && <img src={phase.image} alt="" />}
        <h2>{clearBlockTags(phase.text)}</h2>
    </Container>
}
