import { useMutation, useQueryClient } from '@tanstack/react-query';
import {v4} from 'uuid';
import Network from '../../network';
import { useMemo } from 'react';
import { USER_MODULE_ACTION, useUserModuleDispatch } from '..';
import { useNavigate } from 'react-router';
import { getPlayerGameTitleRoute, getPlayerMenuRoute } from '../../../routes/routes';

const LS_KEY = 'openUserId';

export default function useOpenUser() {
    const navigate = useNavigate();
    const client = useQueryClient();
    const dispatch = useUserModuleDispatch();

    let userId = localStorage.getItem(LS_KEY) || '';

    if (!userId) {
        userId = v4();
        localStorage.setItem(LS_KEY, userId);
    }

    const { mutate, isLoading, error, reset } = useMutation({
        mutationFn: () => Network.loginOpenUser(userId),
        async onSuccess({ firstTime, userId }) {
            console.log('success', userId);
            dispatch({
                type: USER_MODULE_ACTION.SET_USER_ID,
                payload: {
                    userId
                }
            });

            await client.resetQueries({ queryKey: ['user'] });
            await client.invalidateQueries({ queryKey: ['user'] });

            if (firstTime) {
                navigate(getPlayerGameTitleRoute());
            } else {
                navigate(getPlayerMenuRoute());
            }
        },
    });


    return {
        isLoading,
        login: mutate,
        reset,
        error: useMemo(() => {
            if (!error) return null

            //@ts-ignore
            const errorResponse: any = error.response;

            switch (errorResponse.status) {
                case 403:
                    return 'Błędny kod gry.'

                default:
                    return 'Coś poszło nie tak.'
            }
        }, [error])
    }
}

