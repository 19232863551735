import { MainModuleState, MainModuleAction, MAIN_MODULE_ACTION } from ".";

export default function MainReducer(state: MainModuleState, action: MainModuleAction): MainModuleState {
    const { type, payload } = action;
    switch (type) {
        case MAIN_MODULE_ACTION.SET_FORCED_THEME:
            return {
                ...state,
                forcedTheme: payload.theme
            }

        case MAIN_MODULE_ACTION.SET_DISABLE_ANIMATION:
            return {
                ...state,
                disableAnimation: payload.disableAnimation
            }
    }

    return state;
}
