import { createGlobalStyle } from "styled-components";
import { useUser } from "../../modules/user/hooks/use-user";
import CoverGenerator from "../cover-generator";

type Props = {
    onConfirm(bgIndex: number, objIndex: number, titleIndex: number): void
}

const GlobalStyles = createGlobalStyle`
        :root {
            --body-background: #282728 !important;
            --step-bg-color: transparent !important;
        }
    }
`;

export default function GeneratorPage({onConfirm}: Props) {
    const {data: user} = useUser();

    return <>
        <GlobalStyles />
        {user && <CoverGenerator eco={user.score.eco} edu={user.score.edu} gra={user.score.gra} onConfirm={onConfirm} />}
    </>
}
