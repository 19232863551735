import SongWidget from "../special-blocks/song"
import Survey from "../special-blocks/survey"
import RegisterFormPhase from "./register-form-phase"

type Props = {
    phase: any,
    onComplete(): void
}

export default function CustomPhase({ phase, onComplete }: Props) {
    switch (phase.customType) {
        case 'survey':
            return <Survey code={phase.customParams} onComplete={onComplete} />

        case 'song':
            return <SongWidget onComplete={onComplete} />

        case 'register-form':
            return <RegisterFormPhase {...phase.customParams} onComplete={onComplete} />
    }

    return <></>
}
