import { useState } from "react";
import { useForm } from "react-hook-form";
import styled, { createGlobalStyle } from "styled-components";
import CoverGenerator from "../../components/cover-generator";
import PlayerWrapper from "../../components/player-wrapper";

import pattern1Src from '../../assets/patterns/pattern-1.png';

const Container = styled.div`
    .test-form {
        width: 100%;
        min-height: 100vh;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
`;

const GlobalStyles = createGlobalStyle`
    :root {
        --step-bg-color: var(--color3);
    }

    body {
        background: url(${pattern1Src});
        background-size: 25%;
    }
`;

export default function CoverTesterPage() {

    const [view, setView] = useState('intro');

    const { register, watch } = useForm<{ eco: number, edu: number, gra: number }>({
        defaultValues: { eco: 18, edu: 18, gra: 18 }
    });

    const points = watch();

    return <PlayerWrapper>
        <GlobalStyles />
        <Container>
        {view === 'intro' && <div className="test-form">
            <div>
                <p>EKO {points.eco}</p>
                <input type="range" min={0} max={18} step={1} {...register('eco', { valueAsNumber: true })} />
            </div>
            <div>
                <p>EDU {points.edu}</p>
                <input type="range" min={0} max={18} step={1} {...register('edu', { valueAsNumber: true })} />
            </div>
            <div>
                <p>GRA {points.gra}</p>
                <input type="range" min={0} max={18} step={1} {...register('gra', { valueAsNumber: true })} />
            </div>
            <button onClick={() => { setView('generator') }}>Do generatora</button>
        </div>}

        {view === 'generator' && <>
            <CoverGenerator {...points} onConfirm={() => {}} />
        </>}


    </Container></PlayerWrapper>
}
