import Axios from 'axios';
import { GameInfoData } from '.';

/**
 * Axios instance to comunicate with local PHP backend
 */
const APIClient = Axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
    withCredentials: true
});

function getProjectsLists() {
    return APIClient.get('/chapter/project-list')
        .then(res => res.data)
}

function convertArticyProject(dir: string) {
    return APIClient.post('/chapter/convert', {
        projectDir: dir
    });
}

function getFullScenarioList() {
    return APIClient.get('/scenario/all')
        .then(res => res.data)
}

function getScenarioList() {
    console.log('getScenarioList')
    return APIClient.get('/scenario')
        .then(res => res.data)
}

function newScenario(props: {
    name: string,
    forGM: boolean,
    chapter1?: string,
    chapter2?: string,
    chapter3?: string,
    chapter4?: string,
    chapter5?: string
    _id?: string
}) {
    return APIClient.post('/admin/scenario/new', props);
}

function resetGame(gameCode: string) {
    return APIClient.post(`/game/${gameCode}/reset`);
}

function sendSurvey(surveyId: string, data: any) {
    return APIClient.post(`/survey/${surveyId}`, data);
}

function getSurveyList() {
    return APIClient.get('/survey/list')
        .then(res => res.data)
}

function getSurveyRaport(id: string) {
    return APIClient.get(`/survey/${id}/raport`)
        .then(res => res.data)
}

function getChaptersList() {
    return APIClient.get('/chapter')
        .then(res => res.data);
}

function getGMRaport() {
    return APIClient.get('/admin/gm-raport')
        .then(res => res.data);
}

function getGameInfo(code: string): Promise<GameInfoData> {
    return APIClient.get(`/admin/game/${code}`)
        .then(res => res.data);
}

const AdminNetwork = {
    getProjectsLists,
    convertArticyProject,
    getChaptersList,
    getFullScenarioList,
    getScenarioList,
    newScenario,
    resetGame,
    sendSurvey,
    getSurveyList,
    getSurveyRaport,
    getGMRaport,
    getGameInfo
}

export default AdminNetwork;
