import { PropsWithChildren } from "react";
import styled from "styled-components";
import useLogin from "../../modules/user/hooks/use-login";

const Container = styled.div`
    min-height: 100vh;
    width: 100%;
    max-width: 768px;
    margin: 0 auto;

    @media (min-width: 1080px) {
        /* max-width: 1080px; */
        max-width: var(--wrapper-max-width);
    }

    color: var(--step-content-color);

    display: flex;
    flex-direction: column;
    justify-content: var(--step-vertival-justify);
    align-items: stretch;

    background: var(--step-bg-color);

    transition: all .5s;
    `;

export default function PlayerWrapper({ children }: PropsWithChildren<{}>) {

    const { isLoading } = useLogin();

    const showLoader = isLoading;
    const showContent = !showLoader;

    return <Container className="player-wrapper">
        {showLoader && <p>Ładuję...</p>}
        {showContent && <>{children}</>}
    </Container>
}
