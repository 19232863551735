import styled from "styled-components"
import pattern2Src from '../../assets/patterns/pattern-2.png';
import pattern3Src from '../../assets/patterns/pattern-3.png';
import pattern4Src from '../../assets/patterns/pattern-4.png';
import useResetOnCheckpoint from "../../modules/game/hooks/use-reset-on-checkoint";
import classNames from "classnames";
import Loader from "../loader";

type Checkpoint = {
    globals: {
        [key: string]: any
    }
}

type Props = {
    checkpoints: {
        chapter1?: Checkpoint,
        chapter2?: Checkpoint,
        chapter3?: Checkpoint,
        chapter4?: Checkpoint,
        chapter5?: Checkpoint,
    }
}

const Container = styled.div`
    .checkpoint {
        display: grid;

        grid-template-columns: 2fr 2fr 1fr;
        gap: 10px;

        padding: .5em;

        background: #d9d9d9;

        &:nth-child(2n) {
            background: #9d9d9d;
        }

        .buttons {
            display: flex;
            justify-content: center;
            align-items: center;

            position: relative;

            .loader {
                display: none;

                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;

                z-index: 10;
            }

            &.show-loader {
                .loader {
                    display: block;
                }

                button {
                    opacity: 0;
                    pointer-events: none;
                }
            }
        }

        .button {
            text-decoration: none;
            padding: 1em 2em;
            border-radius: 1.5em;
            border: none;
            background: var(--colorError1);
            color: white;
            text-transform: uppercase;

            display: inline-block;

            cursor: pointer;

            &:hover {
                color: black;
                background: var(--colorError2);
            }
        }

    }
`;

export default function Checkpoints({ checkpoints }: Props) {
    return <Container>
        {checkpoints.chapter1 && <CheckpointPanel checkpoint={checkpoints.chapter1} label="Rozdział 1" chapterIndex={1} />}
        {checkpoints.chapter2 && <CheckpointPanel checkpoint={checkpoints.chapter2} label="Rozdział 2" chapterIndex={2} />}
        {checkpoints.chapter3 && <CheckpointPanel checkpoint={checkpoints.chapter3} label="Rozdział 3" chapterIndex={3} />}
        {checkpoints.chapter4 && <CheckpointPanel checkpoint={checkpoints.chapter4} label="Rozdział 4" chapterIndex={4} />}
        {checkpoints.chapter5 && <CheckpointPanel checkpoint={checkpoints.chapter5} label="Rozdział 5" chapterIndex={5} />}
    </Container>
}

export function CheckpointPanel({ checkpoint, label, chapterIndex }: { checkpoint: Checkpoint, label: string, chapterIndex: number }) {

    const { reset, isLoading } = useResetOnCheckpoint(chapterIndex);

    const buttonClasses = classNames({
        "buttons": true,
        "show-loader": isLoading
    })

    return <div className="checkpoint">
        <h3>{label}</h3>
        <CheckPointScore
            eco={checkpoint.globals.Punkty___eco || 0}
            edu={checkpoint.globals.Punkty___edu || 0}
            gra={checkpoint.globals.Punkty___gra || 0}
        />
        <div className={buttonClasses}>
            <div className="loader">
                <Loader />
            </div>
            <button className="button" onClick={_ => reset()}>Zagraj od początku tego rozdziału</button>
        </div>
    </div>
}

const CheckPointScoreContainer = styled.div`
    display: flex;

    .score {

        width: 4em;
        height: 4em;

        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        .label {
            font-size: .75em;
        }

        &.eco {
            background: url(${pattern2Src});
            background-size: 20%;
            background-attachment: fixed;
        }
        &.edu {
            background: url(${pattern3Src});
            background-size: 20%;
            background-attachment: fixed;
        }
        &.gra {
            background: url(${pattern4Src});
            background-size: 20%;
            background-attachment: fixed;
        }
    }
`;

export function CheckPointScore({ eco, edu, gra }: { eco: number | null, edu: number | null, gra: number | null }) {
    return <CheckPointScoreContainer>
        <div className="score eco">
            <div className="label">Eko</div>
            <div className="value">{eco === null ? '-' : eco.toFixed(1)}</div>
        </div>
        <div className="score edu">
            <div className="label">Edu</div>
            <div className="value">{edu === null ? '-' : edu.toFixed(1)}</div>
        </div>
        <div className="score gra">
            <div className="label">Gra</div>
            <div className="value">{gra === null ? '-' : gra.toFixed(1)}</div>
        </div>
    </CheckPointScoreContainer>
}
