import styled from "styled-components"
import { clearBlockTags } from "../rich-text";

const Container = styled.div`
    margin: .5em 0 .5em;

    &.action {


        padding: .5em 0 .5em 2em;

        button {
            background: var(--color3);
            border: none;
            color: white;
            font-family: 'Exo', sans-serif;
            font-weight: 500;
            font-size: 1rem;

            @media (min-width: 768px) {
                font-size: .85rem;
            }

            position: relative;

            border-radius: 1.2em;

            padding: .7em 1em .7em 2em;

            width: 100%;

            text-align: left;


            cursor: pointer;

            &:before {
                position: absolute;
                top: -0em;
                left: -1em;

                content: '?';

                font-family: var(--audiowide);
                font-size: 1.5em;
                color: white;

                width: 1.5em;
                height: 1.5em;

                border-radius: 50%;
                border: .15em solid white;
                box-shadow: 0 0 0 .3em var(--color3);

                background: var(--color2);

                display: flex;
                justify-content: center;
                align-items: center;

            }

            &:hover {
                background: var(--color1);

                &:before {
                    box-shadow: 0 0 0 .3em var(--color1);
                }
            }

            span {
                width: 112%;
                display: inline-block;
                transform-origin: 0 50%;
                transform: scale(.89, 1)
            }
        }
    }

    &.next {

        display: flex;
        justify-content: center;

        button {
            font-size: 1em;
            text-decoration: none;
            padding: 1.1em 5em;
            border-radius: 1.6em;
            border: none;
            background: var(--color3);
            color: white;
            text-transform: uppercase;

            display: block;

            box-shadow: 0 .18em .15em 0 rgba(53,73,94, .45);

            font-weight: 600;

            &:hover {
                cursor: pointer;
                background: var(--color1);
            }
        }
    }

`;

export default function ActionElement({ element, sendAction }: any) {
    return <Container className={`${element.choiceType}`}>
        <button onClick={() => sendAction(element.id)}><span>{clearBlockTags(element.text || 'Dalej')}</span></button>
    </Container>
}
