import { useCallback, useState } from "react";

export default function useCookieAccert() {
    const [cookieAccepped, setCookieAccepted] = useState(localStorage.getItem('cookiesAccepted') || false);

    const acceptCookies = useCallback(() => {
        setCookieAccepted(true);
        localStorage.setItem('cookiesAccepted', '1');
    }, [setCookieAccepted]);

    return {
        cookieAccepped,
        acceptCookies
    }
}
