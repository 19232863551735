import makeStore from "@mborecki/react-create-store";
import reducer from "./reducer";

const CONFIG_MODULE_LS_KEY = 'page_config'

export type MainModuleState = {
    forcedTheme: string | null,
    disableAnimation: boolean,
}

const initMainModuleState = {
    forcedTheme: null,
    disableAnimation: false
}

export enum MAIN_MODULE_ACTION {
    SET_FORCED_THEME,
    SET_DISABLE_ANIMATION
}

export type MainModuleAction = {
    type: MAIN_MODULE_ACTION,
    payload?: any
}

const [
    MainModuleProvider,
    useMainModuleDispatch,
    useMainModuleState
] = makeStore(reducer, initMainModuleState, CONFIG_MODULE_LS_KEY)


export {
    MainModuleProvider,
    useMainModuleDispatch,
    useMainModuleState
}
