import { useMemo } from "react";
import { createGlobalStyle } from "styled-components"

import pattern1Src from '../../assets/patterns/pattern-1.png';
import pattern2Src from '../../assets/patterns/pattern-2.png';
import pattern3Src from '../../assets/patterns/pattern-3.png';
import pattern4Src from '../../assets/patterns/pattern-4.png';

type StyleProps = {
    bodyBackground: string,
    stepBgColor: string,
    stepVerticalJustify: string,
    stepContentColor: string,
    actionMainColor: string,
    actionInsideColor: string,
    actionHoverColor: string,
    listBulletColor: string,
    linkColor: string,
    linkHoverColor: string,
    chatLinkColor: string,
    chatLinkHoverColor: string,
    onLocationBoxBackground: string,
    onLocationNameBoxBackground: string,
    onLocationTextColor: string,
    onLocationNameColor: string

}

const GlobalStyles = createGlobalStyle<StyleProps>`

    :root {
        --body-background: ${({ bodyBackground }) => bodyBackground};
        --step-bg-color: ${({ stepBgColor }) => stepBgColor};
        --step-vertival-justify: ${({ stepVerticalJustify }) => stepVerticalJustify};
        --step-content-color: ${({ stepContentColor }) => stepContentColor};

        --action-main-color: ${({ actionMainColor }) => actionMainColor};
        --action-inside-color: ${({ actionInsideColor }) => actionInsideColor};
        --action-hover-color: ${({ actionHoverColor }) => actionHoverColor};

        --list-bullet-color: ${({ listBulletColor }) => listBulletColor};

        --link-color: ${({ linkColor }) => linkColor};
        --link-hover-color: ${({ linkHoverColor }) => linkHoverColor};

        --dialog-link-color: ${({ chatLinkColor: dialogLinkColor }) => dialogLinkColor};
        --dialog-link-hover-color: ${({ chatLinkHoverColor: dialogLinkHoverColor }) => dialogLinkHoverColor};

        --on-location-box-background: ${({onLocationBoxBackground}) => onLocationBoxBackground};
        --on-location-text-color: ${({onLocationTextColor}) => onLocationTextColor};
        --on-location-name-background: ${({onLocationNameBoxBackground}) => onLocationNameBoxBackground};
        --on-location-name-color: ${({onLocationNameColor}) => onLocationNameColor};
    }

    body {
        background: var(--body-background);
        background-size: 25%;
    }
`;

export default function GameStepStyles({ step }: any) {
    const props = useMemo<StyleProps>(() => {
        const theme = step.theme;
        const inverted = step.invertTheme || false;

        switch (theme) {

            case 'green':
                return {
                    bodyBackground: `url(${pattern2Src})`,
                    stepBgColor: inverted ? '#1e7e37' : 'white',
                    stepVerticalJustify: inverted ? 'center' : 'flex-start',
                    stepContentColor: inverted ? 'white' : 'var(--color4)',
                    actionMainColor: '#0F4A4E',
                    actionInsideColor: '#186B50',
                    actionHoverColor: '#30D6A0',
                    listBulletColor: '#0F4A4E',
                    linkColor: '#0F4A4E',
                    linkHoverColor: '#30D6A0',
                    chatLinkColor: 'var(--color2)',
                    chatLinkHoverColor: 'var(--color3)',
                    onLocationBoxBackground: '#6FB7A9',
                    onLocationNameBoxBackground: '#89E4D2',
                    onLocationTextColor: '#342F2F',
                    onLocationNameColor: '#000000'
                }
            case 'yellow':
                return {
                    bodyBackground: `url(${pattern3Src})`,
                    stepBgColor: inverted ? '#f28a1b' : 'white',
                    stepVerticalJustify: inverted ? 'center' : 'flex-start',
                    stepContentColor: inverted ? 'white' : 'var(--color4)',
                    actionMainColor: '#ce6e18',
                    actionInsideColor: '#EC9719',
                    actionHoverColor: '#FFA31B',
                    listBulletColor: '#CE6E18',
                    linkColor: '#CE6E18',
                    linkHoverColor: '#FFA31B',
                    chatLinkColor: 'var(--color2)',
                    chatLinkHoverColor: 'var(--color3)',
                    onLocationBoxBackground: '#EC9719',
                    onLocationNameBoxBackground: '#FFA31B',
                    onLocationTextColor: '#342F2F',
                    onLocationNameColor: '#000000'
                }
            case 'violet':
                return {
                    bodyBackground: `url(${pattern4Src})`,
                    stepBgColor: inverted ? '#422172' : 'white',
                    stepVerticalJustify: inverted ? 'center' : 'flex-start',
                    stepContentColor: inverted ? 'white' : 'var(--color4)',
                    actionMainColor: '#6237A0',
                    actionInsideColor: '#9F7ED8',
                    actionHoverColor: '#BC95FF',
                    listBulletColor: '#6237A0',
                    linkColor: '#6237A0',
                    linkHoverColor: '#BC95FF',
                    chatLinkColor: 'var(--color2)',
                    chatLinkHoverColor: 'var(--color3)',
                    onLocationBoxBackground: '#9F7ED8',
                    onLocationNameBoxBackground: '#BC95FF',
                    onLocationTextColor: '#342F2F',
                    onLocationNameColor: '#000000'
                }


            case 'red':
                return {
                    bodyBackground: `#871010`,
                    stepBgColor: inverted ? '#871010' : 'white',
                    stepVerticalJustify: inverted ? 'center' : 'flex-start',
                    stepContentColor: inverted ? 'white' : 'var(--color4)',
                    actionMainColor: '#f52727',
                    actionInsideColor: '#ba1d1d',
                    actionHoverColor: '#f15f5f',
                    listBulletColor: '#f52727',
                    linkColor: '#f52727',
                    linkHoverColor: '#f15f5f',
                    chatLinkColor: 'var(--color2)',
                    chatLinkHoverColor: 'var(--color3)',
                    onLocationBoxBackground: '#ba1d1d',
                    onLocationNameBoxBackground: '#f15f5f',
                    onLocationTextColor: '#342F2F',
                    onLocationNameColor: '#000000'
                }

            case 'blue':
            default:
                return {
                    bodyBackground: `url(${pattern1Src})`,
                    stepBgColor: inverted ? 'var(--color7)' : 'white',
                    stepVerticalJustify: inverted ? 'center' : 'flex-start',
                    stepContentColor: inverted ? 'white' : 'var(--color4)',
                    actionMainColor: 'var(--color3)',
                    actionInsideColor: 'var(--color2)',
                    actionHoverColor: 'var(--color1)',
                    listBulletColor: 'var(--color3)',
                    linkColor: 'var(--color3)',
                    linkHoverColor: 'var(--color1)',
                    chatLinkColor: 'var(--color2)',
                    chatLinkHoverColor: 'var(--color3)',
                    onLocationBoxBackground: 'var(--color1)',
                    onLocationNameBoxBackground: 'var(--color3)',
                    onLocationTextColor: '#342F2F',
                    onLocationNameColor: '#000000'
                }
        }
    }, [step]);

    return <GlobalStyles {...props} />
}
