import { useMutation, useQueryClient } from "@tanstack/react-query";
import { USER_MODULE_ACTION, useUserModuleDispatch } from "..";
import Network from "../../network";
import { useNavigate } from "react-router";
import { getClassHomeRoute, getHomeRoute } from "../../../routes/routes";

export default function useLogout() {
    const dispatch = useUserModuleDispatch();
    const client = useQueryClient();
    const navigate = useNavigate();

    const { mutate } = useMutation({
        mutationFn: () => Network.logout(),
        async onSuccess() {
            // await client.invalidateQueries('user');
            await client.resetQueries({ queryKey: ['user', 'logged'] })
            await client.resetQueries({ queryKey: ['game-step'] })

            dispatch({
                type: USER_MODULE_ACTION.RESET_USER_ID
            });

            navigate(getClassHomeRoute());

        }
    });

    return {
        logout: mutate
    }
}
