const orderList = /^\s\s\s\s\d+\.\s(.*)/s;
const unorderList = /^\s\s\s\s\*\s(.*)/s;

const alingTag = /^<align=(.*?)>(.*)/s;
const alignResetTag = /^<\/align>(.*)/s;

const doubleN = /^\n\n(.*)/s;
const NonEnd = /^(.*)\n$/s;

export function clearBlockTags(source: string): string {
    return source
        .replace(/<align=(.*?)>/g, '')
        .replace(/<\/align>/g, '')
        .replace(/<size=(.*?)>/g, '')
        .replace(/<\/size>/g, '')
        .replace(/<b>/g, '')
        .replace(/<\/b>/g, '')
}

export function parseRichText(source: string): string {
    if (!source) return '';
    const blocks = TMPtoBlocks(source);
    const html = BlocksToHTML(blocks);

    return html;
}

function TMPtoBlocks(source: string): any[] {

    let blocks: any[] = [];

    let rest = source;

    let ungoingText = '';
    let inOrderList = false;
    let inUnorderList = false;

    while (rest.length > 0) {

        const alingTagMatch = alingTag.exec(rest);

        if (alingTagMatch) {
            blocks = [
                ...blocks,
                { type: 'text', value: ungoingText },
                { type: 'start-align', value: alingTagMatch[1] }
            ];
            ungoingText = '';
            rest = alingTagMatch[2];
            continue;
        }

        const alignResetMatch = alignResetTag.exec(rest);

        if (alignResetMatch) {
            blocks = [
                ...blocks,
                { type: 'text', value: ungoingText },
                { type: 'reset-align' }
            ];
            ungoingText = '';
            rest = alignResetMatch[1];
            continue;
        }

        const orderListMatch = orderList.exec(rest);

        if (orderListMatch) {
            blocks = [
                ...blocks,
                { type: 'text', value: ungoingText },
                { type: 'order-list-element' }
            ];
            inOrderList = true;
            inUnorderList = false;
            ungoingText = '';
            rest = orderListMatch[1];
            continue;
        }

        const unorderListMatch = unorderList.exec(rest);

        if (unorderListMatch) {
            blocks = [
                ...blocks,
                { type: 'text', value: ungoingText },
                { type: 'unorder-list-element' }
            ];
            inOrderList = false;
            inUnorderList = true;
            ungoingText = '';
            rest = unorderListMatch[1];
            continue;
        }

        const doubleNMatch = doubleN.exec(rest);

        if (doubleNMatch) {

            if (inOrderList || inUnorderList) {
                blocks = [
                    ...blocks,
                    { type: 'text', value: ungoingText },
                    { type: 'list-end' }
                ];
                inOrderList = false;
                inUnorderList = false;
                ungoingText = '';
            }

            rest = doubleNMatch[1];
            continue;
        }

        ungoingText += rest[0];

        rest = rest.slice(1);
        continue;
    }

    blocks = [...blocks, {
        type: 'text',
        value: ungoingText
    }];

    blocks = blocks.filter(b => {
        if (b.type === 'text' && !b.value) {
            return false;
        }

        if (b.type === 'text' && b.value === '\n') {
            return false;
        }

        return true;
    });

    blocks.forEach(b => {
        if (b.type === 'text') {
            const NonEndMatch = NonEnd.exec(b.value);

            if (NonEndMatch) {
                b.value = NonEndMatch[1];
            }
        }
    });

    return blocks;
}

function BlocksToHTML(blocks: any[]): string {

    let result = '';

    let block;

    let inOrderList = false;
    let inUnorderList = false;
    let align: string | null = null;

    let activeText = '';

    function printTag() {
        if (activeText) {

            const text = parseInlineTags(activeText);

            const tag = (inOrderList || inUnorderList) ? 'li' : 'p';
            let style = align ? ` style="text-align: ${align}"` : '';
            result += `<${tag}${style}>${text}</${tag}>`;

            activeText = '';
        }
    }

    while ((block = blocks.shift())) {
        const { type, value } = block;

        switch (type) {
            case 'start-align':
                if (!inOrderList && !inUnorderList) {
                    printTag();
                }
                align = value;
                break;

            case 'reset-align':
                printTag();
                align = null;
                break;

            case 'unorder-list-element':
                printTag();
                if (inOrderList) {
                    result += '</ol>';
                }
                if (!inUnorderList) {
                    result += '<ul>';
                }
                inUnorderList = true;
                inOrderList = false;
                break;

            case 'order-list-element':
                printTag();
                if (inUnorderList) {
                    result += '</ul>';
                }
                if (!inOrderList) {
                    result += '<ol>';
                }
                inUnorderList = false;
                inOrderList = true;
                break;

            case 'list-end':
                printTag();
                if (inOrderList) {
                    result += '</ol>';
                }
                if (inUnorderList) {
                    result += '</ul>';
                }
                inUnorderList = false;
                inOrderList = false;
                break;

            case 'text':
                activeText += value;
                break;
        }
    }

    return result;
}

export function parseInlineTags(source: string): string {
    return source
        .replace(/\n/g, '<br />')
        .replace(/<link=(.*?)>(.*?)<\/link>/g, `<a href=$1 target="_black">$2</a>`);
}
