import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router";
import { getPlayerGameRoute, getPlayerGameTitleRoute } from "../../../routes/routes";
import Network from "../../network";

export default function useResetOnCheckpoint(chapterIndex = 1) {

    const client = useQueryClient();
    const navigate = useNavigate();
    const { mutate, isLoading } = useMutation(['reset-on-checkpoit'], () => {
        return Network.resetChapter(chapterIndex);
    }, {
        async onSuccess() {
            console.log('RESET!');
            await client.invalidateQueries({ queryKey: ['user']});
            await client.invalidateQueries({ queryKey: ['game-step']});

            if (chapterIndex === 1) {
                navigate(getPlayerGameTitleRoute());
            } else {
                navigate(getPlayerGameRoute());
            }
        }
    });

    return {
        reset: mutate,
        isLoading
    }
}
