import { useQuery } from "@tanstack/react-query"
import { Link } from "react-router-dom";
import Loader from "../../components/loader";
import AdminNetwork from "../../modules/network/admin";
import { getSurveyRaportRoute } from "../routes";

export default function ClientZonePage() {
    return <>
        <h1>Strefa readkcji</h1>
        <div>
            <h2>Ankiety</h2>
            <SurveyMenu />
        </div>
    </>
}


function SurveyMenu() {

    const {data, isLoading} = useQuery({
        queryKey: ['survey-list'],
        queryFn: AdminNetwork.getSurveyList
    });

    console.log(data);

    return <>
        {isLoading && <Loader />}
        {!isLoading && <ul>
            {
                data && data.map((s: string) => {
                    return <li key={s}>
                        <Link to={getSurveyRaportRoute(s)}>{s}</Link>
                    </li>
                })
            }
        </ul>}
    </>
}
