import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { getClassHomeRoute, getClientZoneRoute, getCreateNewGameRoute, getGameMasterLiveGameRoute, getGameMasterRoute, getHomeRoute, getOpenPlayerIntroRoute, getPlayerGameReset, getPlayerGameRoute, getPlayerGameTitleRoute, getPlayerIntroRoute, getPlayerMenuRoute, getPrivatePoliticsRoute, getSurveyRaportRoute } from './routes/routes';
import React, { Suspense } from 'react';
import Loader from './components/loader';
import GamePage from './routes/game';
import PlayerMainPage from './routes/player';
import GameMasterCreateNewGamePage from './routes/game-master/game/new-game';
import GameResetPage from './routes/game/reset';
import GameTitle from './components/game-title';
import ClientZonePage from './routes/redakcja';
import SurveyRaportPage from './routes/redakcja/survey-raport';
import PlayerMenuPage from './routes/player/menu';
import CookiePopup from './components/cookies';
import PolicyPage from './routes/policy';
import CoverTesterPage from './routes/cover-tester';
import OpenPlayerMainPage from './routes/player/open';
import ClassHomePage from './routes/class-home';

const HomePage = React.lazy(() => import('./routes/home'));
const GameMasterPage = React.lazy(() => import('./routes/game-master'));
const GameMasterGamePage = React.lazy(() => import('./routes/game-master/game'));

export default function App() {
    return (
        <>
            <BrowserRouter>
                <Suspense fallback={<Loader />}>
                    <Routes>
                        <Route path={getHomeRoute()} element={<HomePage />} />
                        <Route path={getClassHomeRoute()} element={<ClassHomePage />} />
                        <Route path={getGameMasterRoute()} element={<GameMasterPage />} />
                        <Route path={getCreateNewGameRoute()} element={<GameMasterCreateNewGamePage />} />
                        <Route path={getGameMasterLiveGameRoute()} element={<GameMasterGamePage />} />
                        <Route path={getPlayerMenuRoute()} element={<PlayerMenuPage />} />
                        <Route path={getPlayerGameRoute()} element={<GamePage />} />
                        <Route path={getPlayerGameTitleRoute()} element={<GameTitle />} />
                        <Route path={getPlayerIntroRoute()} element={<PlayerMainPage />} />
                        <Route path={getOpenPlayerIntroRoute()} element={<OpenPlayerMainPage />} />

                        <Route path={getPlayerGameReset()} element={<GameResetPage />} />

                        <Route path={getClientZoneRoute()} element={<ClientZonePage />} />
                        <Route path={getSurveyRaportRoute()} element={<SurveyRaportPage />} />
                        <Route path={getPrivatePoliticsRoute()} element={<PolicyPage />} />

                        <Route path={'/cover-test'} element={<CoverTesterPage />} />

                        <Route element={<HomePage />} />
                    </Routes>
                </Suspense>
                <CookiePopup />
            </BrowserRouter>
        </>
    )
}
