import { useRef, useState } from "react";
import styled, { createGlobalStyle } from "styled-components"
import { useUser } from "../../../modules/user/hooks/use-user";
import CoverCanvas, { CoverCanvasAPI } from "../../cover-generator/cover-canvas";
import Loader from "../../loader";
import dzieciakiSrc from '../../../assets/dzieciaki.png';
import { Link } from "react-router-dom";
import { getPlayerMenuRoute } from "../../../routes/routes";

const Container = styled.div`

    max-width: 800px;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    min-height: 100vh;

    justify-content: space-around;
    position: relative;

    .gfx {
        flex: 0 1 auto;

        max-width: 90vw;
        max-height: 100vh;

        width: 100vw;

        aspect-ratio: 9/15;
        margin: 0 auto;

        position: relative;

        // display: flex;
        // flex-direction: column;
        // justify-content: center;

        padding-top: 2em;
    }

    .dzieciaki {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        background: url(${dzieciakiSrc}) 50% 100% no-repeat;
        background-size: contain;
    }

    .cover {
        width: 75%;
        margin: 0 auto;
        aspect-ratio: 9/16;

        box-shadow: 5px 5px 25px 0 rgba(0,0,0,.63);

        canvas {
            width: 100%;
            max-height: 100%;
        }
    }

    .buttons {
        margin: 2em;

        display:flex;
        flex-direction: column;
        justify-content: space-around;

        position: relative;

        align-items: center;

        .loader {
            display: none;

            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            z-index: 10;
        }

        &.show-loader {
            .loader {
                display: block;
            }

            button, a {
                opacity: 0;
                pointer-events: none;
            }
        }
    }

    .button {
        text-decoration: none;
        padding: 1em 2em;
        border-radius: 1.5em;
        border: none;
        background: var(--action-main-color);
        border: 2px solid var(--action-main-color);
        color: white;
        text-transform: uppercase;
        font-size: .8rem;
        font-weight: 600;
        box-shadow: 0 3px 3px 1px #d9d9d9;

        text-align: center;

        width: 100%;
        max-width: 400px;

        margin: .5em 0;

        cursor: pointer;
        display: block;

        box-sizing: border-box;

        &:hover {
            background: var(--action-hover-color);
            border-color: var(--action-hover-color);
        }

        &.inverted {
            background: white;
            border-color: var(--action-main-color);
            color: var(--action-main-color);

            &:hover {
                background: var(--action-hover-color);
                border-color: var(--action-hover-color);
                color: white;
            }
        }
    }

    @media (min-aspect-ratio: 9/16) {
        .gfx {
            width: auto;
            height: calc(90vh - 2em);
        }
    }
`;

const GlobalStyles = createGlobalStyle<{ background?: string }>`
    :root {
        --step-bg-color: white;
    }

    .player-wrapper{
        position: relative;
        &:before {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            content: '';

            background: ${({ background }) => background};
            background-size: cover;
            background-position: 50% 50%;
            opacity: .25
        }
    }
`;

export default function GameEndEndPage() {
    const { data: user, isLoading } = useUser();
    const coverRef = useRef<CoverCanvasAPI>(null);

    const [imageData, setImageData] = useState<string>();

    return <Container className="xxx-xxx">
        <GlobalStyles background={`url(${imageData}) white`} />
        <div className="gfx">
            <div className="cover">
                {isLoading && <Loader />}
                {user?.cover && <CoverCanvas ref={coverRef} {...user.cover} onDraw={(imgData) => {
                        setImageData(imgData)
                    }} />}
            </div>
            <div className="dzieciaki">

            </div>
        </div>
        <div className="buttons">
            <button className="button" onClick={() => {
                if (coverRef.current) coverRef.current.download();
            }}>
                Pobierz swoją grafikę
            </button>
            <Link to={getPlayerMenuRoute()} className="button inverted">Panel gracza</Link>
        </div>
    </Container>
}
