import Axios from 'axios';

/**
 * Axios instance to comunicate with local PHP backend
 */
const APIClient = Axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
    withCredentials: true
});

console.log('process.env.REACT_APP_SERVER_URL', process.env.REACT_APP_SERVER_URL);

type GetConfigResponse = {
    serverTime: number
}

function getConfig(): Promise<GetConfigResponse> {
    return APIClient.get('/config')
        .then((response) => {
            return {
                serverTime: response.data.time
            }
        });
}

export type NewGameParams = {
    description: string,
    scenario: string,
    playerCount: number
}

export type NewGameResponse = {
    code: string,
    playerUrl: string
}

function createNewGame(params: NewGameParams): Promise<NewGameResponse> {
    return APIClient.post('/game/new', params)
        .then(respose => respose.data)
}

export type UserResponse = {
    userId: string,
    firstTime: boolean
}

function login(userId?: string): Promise<UserResponse> {
    return APIClient.post('/user/login', { userId })
        .then(response => response.data)
}

function loginOpenUser(userId?: string): Promise<UserResponse> {
    return APIClient.post('/user/openlogin', { userId })
        .then(response => response.data)
}

function logout(): Promise<UserResponse> {
    return APIClient.post('/user/logout');
}

function getGameStep() {
    return APIClient.get(`/game/step`)
        .then(response => response.data);
}

function sendAction(actionId: string) {
    return APIClient.post(`/game/action`, {
        actionId
    });
}

function joinGame(gameCode: string) {
    return APIClient.post(`/game/${gameCode}/join`);
}

function getGameMaster() {
    return APIClient.get('/game-master')
        .catch(_ => null)
}

function gameMasterRegister(data: any) {
    return APIClient.post(`/game-master/register`, data);
}

function gameMasterLogin(data: any) {
    return APIClient.post(`/game-master/login`, data);
}

function gameMasterLogout() {
    return APIClient.post('/game-master/logout');
}

function getGameMasterGameList() {
    return APIClient.get('/game-master/games')
        .then(res => res.data)
}

export type PlayerData = {
    token: string,
    used: boolean,
    results: PlayerGameResult[]
}

export type PlayerGameResult = {
    chapter: number,
    eco: number,
    edu: number,
    gra: number,
    createdAt: string,
    ending: string,
}

export type GameInfoData = {
    code: string,
    createdAt: string,
    description: string,
    gameMaster: string,
    players: PlayerData[],
    scenario: {
        chapter1: string
        chapter2: string
        chapter3: string
        chapter4: string
        chapter5: string
        createdAt: string
        forGM: boolean
        name: string
    }
}

function getGameInfo(code: string): Promise<GameInfoData> {
    return APIClient.get(`/game-master/game/${code}`)
        .then(res => res.data);
}

function getDiffRaport(code: string) {
    return APIClient.get(`/game-master/diff-raport/${code}`)
        .then(res => res.data)
}

function nextChapter() {
    return APIClient.post('/game/next-chapter');
}

function getUser() {
    return APIClient.get('/user')
        .then(res => res.data)
        .catch(res => null)
}

function getCommonUser() {
    return APIClient.get('/user/common')
        .then(res => res.data)
        .catch(res => null)
}

function getLoggedUser() {
    return APIClient.get('/user/logged')
        .then(res => res.data)
        .catch(res => null)
}

function resetChapter(chapterIndex: number) {
    return APIClient.post('/game/reset', {
        chapter: chapterIndex
    });
}

function addPlayers(gameCode: string, playersCount: number) {
    return APIClient.post(`/game-master/game/${gameCode}/new-players`, {
        playersCount
    }).then(res => res.data);
}

function getGameStats(gameCode: string) {
    return APIClient.get(`/game-master/game/${gameCode}/stats`)
        .then(res => res.data)
}

function saveGameCover(bgIndex: number, objIndex: number, titleIndex: number) {
    return APIClient.post('/game/cover', {
        bgIndex, objIndex, titleIndex
    });
}

const Network = {
    getConfig,
    createNewGame,
    login,
    loginOpenUser,
    logout,
    getGameStep,
    sendAction,
    joinGame,
    getGameMaster,
    gameMasterLogin,
    gameMasterRegister,
    gameMasterLogout,
    getGameMasterGameList,
    getGameInfo,
    getDiffRaport,
    nextChapter,
    getUser,
    getLoggedUser,
    getCommonUser,
    resetChapter,
    addPlayers,
    getGameStats,
    saveGameCover
}

export default Network;
