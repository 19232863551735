import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from "react";

type Props = {
    bgIndex: number;
    objIndex: number;
    titleIndex: number;
    onDraw?(imgData: string): void
}

export type CoverCanvasAPI = {
    download(): void,
}

export type Asset = {
    url: string,
    eco?: number,
    edu?: number,
    gra?: number
}

export const BACKGROUNDS: Asset[] = [
    { url: '/cover/backgrounds/tlo-01.jpg', eco: 0, edu: 0, gra: 0 },
    { url: '/cover/backgrounds/tlo-02.jpg', eco: 2, edu: 0, gra: 0 },
    { url: '/cover/backgrounds/tlo-03.jpg', eco: 4, edu: 0, gra: 0 },
    { url: '/cover/backgrounds/tlo-04.jpg', eco: 6, edu: 0, gra: 0 },
    { url: '/cover/backgrounds/tlo-05.jpg', eco: 8, edu: 0, gra: 0 },
    { url: '/cover/backgrounds/tlo-06.jpg', eco: 10, edu: 0, gra: 0 },
    { url: '/cover/backgrounds/tlo-07.jpg', eco: 12, edu: 0, gra: 0 },
    { url: '/cover/backgrounds/tlo-08.jpg', eco: 14, edu: 0, gra: 0 },
    { url: '/cover/backgrounds/tlo-09.jpg', eco: 16, edu: 0, gra: 0 },
    { url: '/cover/backgrounds/tlo-10.jpg', eco: 18, edu: 0, gra: 0 },
]

export const OBJECTS: Asset[] = [
    { url: '/cover/objects/statek-01.png', eco: 0, edu: 0, gra: 0 },
    { url: '/cover/objects/statek-02.png', eco: 0, edu: 0, gra: 2 },
    { url: '/cover/objects/statek-03.png', eco: 0, edu: 0, gra: 4 },
    { url: '/cover/objects/statek-04.png', eco: 0, edu: 0, gra: 6 },
    { url: '/cover/objects/statek-05.png', eco: 0, edu: 0, gra: 8 },
    { url: '/cover/objects/statek-06.png', eco: 0, edu: 0, gra: 10 },
    { url: '/cover/objects/statek-07.png', eco: 0, edu: 0, gra: 12 },
    { url: '/cover/objects/statek-08.png', eco: 0, edu: 0, gra: 14 },
    { url: '/cover/objects/statek-09.png', eco: 0, edu: 0, gra: 16 },
    { url: '/cover/objects/statek-10.png', eco: 0, edu: 0, gra: 18 },
]

export const TITLES: Asset[] = [
    { url: '/cover/title/tytul-01.png', eco: 0, edu: 0, gra: 0 },
    { url: '/cover/title/tytul-02.png', eco: 0, edu: 2, gra: 0 },
    { url: '/cover/title/tytul-03.png', eco: 0, edu: 4, gra: 0 },
    { url: '/cover/title/tytul-04.png', eco: 0, edu: 6, gra: 0 },
    { url: '/cover/title/tytul-05.png', eco: 0, edu: 8, gra: 0 },
    { url: '/cover/title/tytul-06.png', eco: 0, edu: 10, gra: 0 },
    { url: '/cover/title/tytul-07.png', eco: 0, edu: 12, gra: 0 },
    { url: '/cover/title/tytul-08.png', eco: 0, edu: 14, gra: 0 },
    { url: '/cover/title/tytul-09.png', eco: 0, edu: 16, gra: 0 },
    { url: '/cover/title/tytul-09.png', eco: 0, edu: 18, gra: 0 },
]

const CoverCanvas = forwardRef<CoverCanvasAPI, Props>(({bgIndex, objIndex, titleIndex, onDraw}, ref) => {

    const canvasRef = useRef<HTMLCanvasElement>(null);

    useImperativeHandle(ref, () => ({
        download() {
            if (canvasRef.current) {
                var a = document.createElement('a');
                a.href = canvasRef.current.toDataURL("image/png");
                a.style.display = 'none';
                a.setAttribute('download', 'latajacy_talerz_okladka.png');
                document.body.appendChild(a);
                a.click();
            }
        }
    }));

    const backgroundRef = useRef(new Image());
    const objectRef = useRef<HTMLImageElement>(new Image());
    const titleRef = useRef<HTMLImageElement>(new Image());

    const draw = useCallback(() => {
        const objY = -3;

        if (!canvasRef.current) return;
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        if (!ctx) return;

        if (backgroundRef.current) {
            ctx.drawImage(backgroundRef.current, 0, 0);
        }

        if (objectRef.current) {
            ctx.drawImage(objectRef.current, 0, (objY/100) * canvas.height);
        }

        if (titleRef.current) {
            ctx.drawImage(titleRef.current, 0, 0, 1080, 1920);
        }

        if (onDraw && canvasRef.current) {
            onDraw(canvasRef.current.toDataURL("image/png"));
        }
    }, [onDraw]);

    useEffect(() => {

        const bg = backgroundRef.current;
        const obj = objectRef.current;
        const title = titleRef.current;

        if (bg) {
            bg.addEventListener('load', draw);
        }
        if (obj) {
            obj.addEventListener('load', draw);
        }
        if (title) {
            title.addEventListener('load', draw);
        }

        return () => {
            if (bg) {
                bg.removeEventListener('load', draw);
            }
            if (obj) {
                obj.removeEventListener('load', draw);
            }
            if (title) {
                title.removeEventListener('load', draw);
            }
        }
    }, [draw]);

    useEffect(() => {
        if (backgroundRef.current) {
            backgroundRef.current.src = BACKGROUNDS[bgIndex]?.url;
        }
    }, [bgIndex]);

    useEffect(() => {
        if (objectRef.current) {
            objectRef.current.src = OBJECTS[objIndex]?.url;
        }
    }, [objIndex]);

    useEffect(() => {
        if (titleRef.current) {
            titleRef.current.src = TITLES[titleIndex]?.url;
        }
    }, [titleIndex]);

    return <>
        <canvas ref={canvasRef} width={1080} height={1920} />
    </>
});

export default CoverCanvas;
