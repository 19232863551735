import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import useResetOnCheckpoint from "../../modules/game/hooks/use-reset-on-checkoint";
import Network from "../../modules/network";
import { getPlayerGameRoute } from "../../routes/routes";
import CoverCanvas, { CoverCanvasAPI } from "../cover-generator/cover-canvas";
import { CheckPointScore } from "./checkpoints";

const Container = styled.div`
    padding: .5em;
    margin: 1em 0 .5em;
    border-radius: 1em;

    background: var(--color1);
    color: black;

    .button {
        text-decoration: none;
        padding: 1em 2em;
        border-radius: 1.5em;
        border: none;
        background: var(--color3);
        color: white;
        text-transform: uppercase;
        font-size: 1rem;
        font-weight: normal;

        display: inline-block;

        &:hover {
            background: var(--color2);
        }
    }

    &.in-progress {
        display: flex;
        flex-direction: column;

        align-items: center;

        p {
            margin: 1em 0;
        }

        .button {
            margin: 2em 0 1em;
        }
    }

    &.post-game {
        display: flex;
        flex-direction: column;

        align-items: center;

        text-align: center;

        p {
            margin: 1em 0;
        }

        .button {
            margin: 2em 0 1em;
        }
    }

    &.paused {
        display: flex;
        flex-direction: column;

        align-items: center;

        p {
            margin: 1em 0;
        }

        .button {
            margin: 2em 0 1em;
        }
    }

    &.ended {
        display: flex;
        flex-direction: column;

        align-items: center;

        p {
            margin: .5em 0;
        }

        .button {
            margin: 2em 0 1em;
        }

        .cover {
            max-width: 600px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            margin: 1em 0;

            canvas {
                width: 100%;
            }

            .cover-buttons {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    &.next-chapter {
        display: flex;
        flex-direction: column;

        align-items: center;

        p {
            margin: .5em 0;
        }

        .button {
            margin: 2em 0 1em;
        }
    }

    .button.reset {
        font-size: .7em;
    }
`;

type Props = {
    gameStatus: 'in-progress' | 'paused' | 'ended' | 'next-chapter' | 'post-game';
    score: any,
    endingType: string,
    chapter: number,
    cover: any
}

export default function GameInfo({ gameStatus, score, endingType, chapter, cover }: Props) {

    const client = useQueryClient();
    const navigate = useNavigate();
    const { reset } = useResetOnCheckpoint(chapter);

    const coverRef = useRef<CoverCanvasAPI>(null);

    const { mutate } = useMutation(['next-chapter'], () => {
        return Network.nextChapter();
    }, {
        async onSuccess() {
            await client.invalidateQueries(['game-step'], {
                refetchType: 'all'
            }, {});
            navigate(getPlayerGameRoute());
        }
    });

    return <Container className={gameStatus}>
        {
            gameStatus === 'in-progress' && <>
                <p>Twoja gra jest aktywna!</p>
                <Link className="button" to={getPlayerGameRoute()}>Kontynuuj grę</Link>
                <button className="button reset" onClick={_ => reset()}>Zagraj od początku aktywnego rozdziału</button>
            </>
        }
        {
            gameStatus === 'ended' && <>
                <p>Skończyłeś grę</p>
                <div style={{ fontSize: '1.8rem' }}>
                    <CheckPointScore
                        eco={score.eco || 0}
                        edu={score.edu || 0}
                        gra={score.gra || 0}
                    />
                </div>
                {cover && <div className="cover">
                    <div className="canvas">
                        <CoverCanvas
                            ref={coverRef}
                            bgIndex={cover.bgIndex}
                            objIndex={cover.objIndex}
                            titleIndex={cover.titleIndex}
                        />
                    </div>
                    <div className="cover-buttons">
                        <button className="button" onClick={() => {
                            if (coverRef.current) coverRef.current.download();
                        }}>Pobierz</button>
                    </div>
                </div>}
            </>
        }
        {
            gameStatus === 'post-game' && <>
                <p>Historia się skończyła ale nadal trzeba wysłać grę na konkurs</p>
                <Link className="button" to={getPlayerGameRoute()}>Przygotowanie zgłoszenia</Link>
                <div style={{ fontSize: '1.8rem' }}>
                    <CheckPointScore
                        eco={score.eco || 0}
                        edu={score.edu || 0}
                        gra={score.gra || 0}
                    />
                </div>
            </>
        }
        {
            gameStatus === 'paused' && <>
                <p>To narazie tyle!</p>
                <p>Wróć później, żeby zagrać dalej!</p>
            </>
        }
        {
            gameStatus === 'next-chapter' && <>
                <p>Możesz grać dalej</p>
                <button className="button" onClick={_ => mutate()}>Następny rozdział</button>
            </>
        }
    </Container>
}
