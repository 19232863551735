import useGameStep from "../../modules/game/hooks/use-game-step";
import useDebugTools from "../../modules/main/hooks/use-debug-tools";
// import DebugTools from "../debug";
import ChapterEnding from "./chapter-ending";
import ChapterTitle from "./chapter-title";
import GameStepWidget from "./game-step";
import GameStepStyles from "./game-step-styles";

export default function GameView() {
    const { isLoading, step, error, showChapterTitle, chapterTitleParams, showChapterEnding, chapterEndingParams } = useGameStep();
    const { forcedTheme } = useDebugTools();

    const showLoader = isLoading;
    const showError = Boolean(error);
    const showStep = !isLoading && !showError && !showChapterTitle && !showChapterEnding;


    return <>
        {/* <DebugTools step={step} /> */}
        {showError && <p>Error........</p>}
        {showLoader && <p>Ładowanie...</p>}
        {
            step && <>
                <GameStepStyles step={{ ...step.step, theme: forcedTheme || step.step.theme }} />
                {showStep && <GameStepWidget step={step} />}
                {showChapterTitle && <ChapterTitle screen={chapterTitleParams} />}
                {showChapterEnding && <ChapterEnding next={chapterEndingParams.next} chapterIndex={chapterEndingParams.chapterIndex} testerSurvey={chapterEndingParams.addSurveyForTester || false}  />}
            </>
        }
    </>

}
