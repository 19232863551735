import { useMemo } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router";
import { USER_MODULE_ACTION, useUserModuleDispatch } from "..";
import { getPlayerGameTitleRoute, getPlayerMenuRoute } from "../../../routes/routes";
import Network from "../../network";

export default function useLogin() {
    const dispatch = useUserModuleDispatch();
    const navigate = useNavigate();
    const client = useQueryClient();

    const { mutate, isLoading, error, reset } = useMutation({
        mutationFn: (userToken: string) => Network.login(userToken),
        async onSuccess({ firstTime }, userToken) {
            dispatch({
                type: USER_MODULE_ACTION.SET_USER_ID,
                payload: {
                    userId: userToken
                }
            });

            await client.resetQueries({ queryKey: ['user'] });
            await client.invalidateQueries({ queryKey: ['user'] });

            if (firstTime) {
                navigate(getPlayerGameTitleRoute());
            } else {
                navigate(getPlayerMenuRoute());
            }
        },
    });

    return {
        isLoading,
        login: mutate,
        reset,
        error: useMemo(() => {
            if (!error) return null

            //@ts-ignore
            const errorResponse: any = error.response;

            switch (errorResponse.status) {
                case 403:
                    return 'Błędny kod gry.'

                default:
                    return 'Coś poszło nie tak.'
            }
        }, [error])
    }
}
