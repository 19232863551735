import { Link } from "react-router-dom";
import styled, { createGlobalStyle } from "styled-components";
import { getHomeRoute } from "./routes";

const Container = styled.div`
    min-height: 100vh;
    width: 100%;
    max-width: 768px;
    margin: 0 auto;

    @media (min-width: 1080px) {
        max-width: 1080px;
    }

    color: #333;


    display: flex;
    flex-direction: column;
    align-items: stretch;

    .wrapper {
        background: white;
        padding: 0 2em;
        min-height: 100vh;
    }

    .back {
        padding: 1em 0;
        display: inline-block;
    }
`;

const GlobalCSS = createGlobalStyle`
    body {
        background: var(--color1);
    }
`;

export default function PolicyPage() {
    return <Container>
        <GlobalCSS />
        <div className="wrapper">
            <Link className="back" to={getHomeRoute()}>Powrót</Link>
            <h1>Polityka prywatności</h1>
            <p>Games Development Mitzner i Reszta spółka jawna z siedzibą w Warszawie, ul. Kredytowa 6/22, 00-062, jako właściciel i Administrator (dalej: Administrator) serwisu EkoHakaton (dalej: Serwis), przedstawia poniżej informacje dotyczące zastosowania i wykorzystania w Serwisie plików cookies.</p>
            <p>
            W Serwisie administrowanym przez Administratora wykorzystywane są następujące rodzaje plików cookies:
            </p>
            <ul>
                <li>necessary – niezbędne do prawidłowego funkcjonowania strony;</li>
                <li>statistics – służące do zbierania statystyk odwiedzin i aktywności za pomocą narzędzia Google Analytics.</li>
            </ul>
            <p>Nasze pliki cookies to:</p>
            <ul>
                <li>pliki sesyjne – wygasają po zakończeniu sesji, np. wylogowaniu się ze strony internetowej, wg parametrów określonych w Twojej przeglądarce internetowej;</li>
                <li>pliki trwałe – nie są kasowane w momencie zamknięcia okna przeglądarki; wykorzystywane są, aby pomóc nam wspierać komfort korzystania z naszych Serwisów (np. zmiana wyglądu lub wersji stron).</li>
            </ul>
            <p>Serwis zewnętrzny Google LLC zapewniający usługi analityczne ma własną politykę cookies <a href="https://policies.google.com/technologies/cookies" target="_blank" rel="noreferrer" >(https://policies.google.com/technologies/cookies)</a>. Może dodać dane zebrane podczas Twojej wizyty do zbioru danych pozyskanych na Twój temat z innych źródeł.</p>
            <p>W każdej chwili możesz edytować ustawienia prywatności na Twoim urządzeniu w ramach ustawień swojej przeglądarki m.in. w zakresie instalowania plików cookies. W każdej chwili możesz też usunąć z pamięci swojego urządzenia pliki cookies zapisane w trakcie korzystania z Serwisu. Ograniczenia w stosowaniu plików cookies mogą utrudnić lub uniemożliwić korzystanie z naszego Serwisu.</p>
            <p>Instalowanie plików cookies lub uzyskiwanie do nich dostępu nie powoduje zmian w Twoim urządzeniu ani oprogramowaniu zainstalowanym na tym urządzeniu.</p>
        </div>
    </Container>
}
