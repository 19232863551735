import { Link } from "react-router-dom";
import styled from "styled-components";
import PageHeader from "../../components/page-header";
import { getGameMasterRoute, getPlayerIntroRoute } from "../routes";
import loginBgSrc from '../../assets/login-background.jpg';
// eslint-disable-next-line
import cyberCircle from '!file-loader!../../assets/cyber-circle.svg';
import HomeFooter from "./footer";

const Container = styled.div`

    width: 100%;
    max-width: 1080px;
    margin: 0 auto;

    display: flex;
    flex-direction: column;

    .wrapper {
        overflow: hidden;

        display: flex;
        flex-direction: column;

        padding: 0 2em 1em;
    }

    .intro {
        margin: 2em 0;
    }

    p {
        margin: 1em 0;
    }

    nav {
        display: grid;
        grid-template-columns: 1fr 1fr;

        .player,
        .game-master {
            height: 60vh;

            padding: 0 1em;

            display: flex;
            justify-content: center;
            align-items: center;

            text-align: center;
        }

        .player {
            background: url(${loginBgSrc}) 50% 50% no-repeat var(--color1);
            background-size: 80%;

            a {
                background: var(--color3);

                &:hover {
                    cursor: pointer;
                    background: var(--color2);
                }
            }
        }

        .game-master {
            background: url(${cyberCircle}) 50% 50% no-repeat #f28a1b;
            background-size: 50%;

            a {
                background: #ce6e18;

                &:hover {
                    cursor: pointer;
                    background: #FFA31B;
                }
            }
        }

        a {
            font-size: 1em;
            text-decoration: none;
            padding: 1.1em 4em;
            border-radius: 1.6em;
            border: none;
            background: var(--action-main-color);
            color: white;
            text-transform: uppercase;

            display: block;

            box-shadow: 0 .18em .15em 0 rgba(53,73,94, .45);

            font-weight: 600;

            &:hover {
                cursor: pointer;
            }
        }



        @media(max-width: 600px) {
            grid-template-columns: 1fr;
            grid-template-rows: 1fr 1fr;

            .player,
            .game-master {
                height: auto;
                padding: 3em 0;
            }
        }
    }
`;

export default function ClassHomePage() {
    return <Container>
        <div className="wrapper">
            <PageHeader color1="var(--color3)" color2="var(--color6)" color3={'#18345a'} />
            <div className="intro">
                <p>
                    Za chwilę rozpoczniesz grę „EkoHakaton - Latający Talerz”, która opowiada o przygodzie Staszka i jego przyjaciół. Podejmuj decyzje, rozwiązuj trudne sytuacje i pokieruj pracami nad projektem własnej gry.
                </p>
                <p>
                    By rozpocząć rozgrywkę, wybierz jedną z poniższych opcji.
                </p>
            </div>
            <nav>
                <div className="player">
                    <Link to={getPlayerIntroRoute()}>Jestem uczniem</Link>
                </div>
                <div className="game-master">
                    <Link to={getGameMasterRoute()}>Jestem nauczycielem</Link>
                </div>
            </nav>
        </div>
        <HomeFooter />
    </Container>
}
