import classNames from "classnames";
import React from "react";
import { useState, useMemo, useEffect, useCallback } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { clearBlockTags, parseRichText } from "../rich-text";
import ActionElement from "./action";
import TextBox from "./text-box";

type Props = {
    section: any,
    sendAction(id: string): void
}

const Container = styled.div<{ showHeader: boolean }>`
    @media(orientation: landscape) and (min-height: 800px) {

        width: 100%;
        height: 100vh;

        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;

        h2 {
            text-align: center;

            width: 112%;
            transform-origin: 0;
            transform: scale(.89,1);

            margin: 0;
            padding: .5em 0 0;

            font-size: 2.5rem;
        }

        .gfx {
            position: relative;
            flex: 0 0 75%;
            overflow: hidden;

            .background {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;

                img {
                    width: 100%;
                    max-height: 100%;
                    object-fit: contain;
                }
            }

            .characters {
                z-index: 0;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                overflow: hidden;

                .character {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;

                    text-align: center;

                    img.bg {
                        display: none;
                    }

                    img {
                        margin: 0 auto;
                        height: 100%;
                        width: auto;
                    }

                    &.in-background {

                        img.bg {
                            display: block;
                            filter: brightness(0) invert(1);
                            opacity: 1;
                        }

                        img {
                            opacity: .5;
                        }
                    }
                }
            }
        }

        .main {
            position: relative;

            display: flex;
            flex-direction: column;

        }

        .location-content {
            position: absolute;

            padding-bottom: 1.5em;

            min-height: calc(25% - 1.5em);
            left: 0;
            right: 0;
            bottom: 0;

            display: flex;
            align-items: stretch;
        }
    }

    @media (orientation: portrait) or (max-height: 800px) {

        width: 100%;
        min-height: 100vh;

        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;

        margin-bottom: 2em;

        h2 {
            text-align: center;

            width: 112%;
            transform-origin: 0;
            transform: scale(.89,1);

            margin: 0;
            padding: .5em 0 0;
        }

        .main {
            position: relative;

            display: flex;
            flex-direction: column;

        }

        .gfx {
            position: relative;
            overflow: hidden;

            img.background {
                width: 100%;
                max-height: 100%;
                object-fit: contain;
            }

            padding: 0 0 2em;

            .characters {
                z-index: 0;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;

                .character {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;

                    text-align: center;

                    img.bg {
                        display: none;
                    }

                    img {
                        margin: 0 auto;
                        height: 100%;
                        width: auto;
                    }

                    &.in-background {

                        img.bg {
                            display: block;
                            filter: brightness(0) invert(1);
                            opacity: 1;
                        }

                        img {
                            opacity: .5;
                        }
                    }
                }
            }
        }

        .location-content {
            display: flex;
            flex-direction: column;
        }
    }
`;

const GlobalCSS = createGlobalStyle`
    :root {
        --wrapper-max-width: 1360px !important;
    }
`

export default function LocationPhase({ section, sendAction }: Props) {

    const { location } = section;
    const [stepIndex, setStepIndex] = useState(0);

    const step = useMemo(() => {
        return section.steps[stepIndex]
    }, [section, stepIndex]);

    useEffect(() => {
        const images: string[] = [];

        section.steps.forEach((s: any) => {
            s.characters.forEach((c: any) => {
                images.push(c.avatar);
            });
        });

        images.forEach((i) => {
            const img = document.createElement('img')
            img.src=i;
        });
    }, [section]);

    useEffect(() => {
        if (step.type === 'player-actions') {
            if (step.actions[0]?.choiceType === 'next') {
                sendAction(step.actions[0].id);
            }
        }
    }, [step, sendAction]);

    const onNext = useCallback(() => {
        // debugger;
        if (stepIndex < section.steps.length-1) {
            setStepIndex(v => v + 1)
        } else if (section.onEndActionId) {
            sendAction(section.onEndActionId);
        } else {
            const action = step.elements.find((e: any) => e.type === 'action' )
            if (action) {
                sendAction(action.id);
            }
        }
    }, [sendAction, section, stepIndex, setStepIndex, step]);

    const showHeader = Boolean(section.header);
    const showNext = !Boolean(step.elements.find((e: any) => {return (e.type === 'action') && (e.choiceType === 'action')}));

    return <Container showHeader={showHeader}>
        <GlobalCSS />
        <div className="header">
            {showHeader && <h2>{clearBlockTags(section.header)}</h2>}
        </div>
        <div className="main">
            <div className="gfx">
                <div className="background">
                    <img src={location.image} alt="" className="background" />
                </div>
                <div className="characters">
                    {
                        step.characters.map((c: any) => {
                            const classes = classNames({
                                character: true,
                                "in-background": c.layer !== 0
                            })
                            return <React.Fragment key={c.avatar}>
                                {c.layer !== 0 && <div className={classes} style={{ zIndex: -c.layer, transform: `translate(${c.position * 10}% ,0)` }}>
                                    <img className="bg" src={c.avatar} alt="" />
                                </div>}
                                <div className={classes} style={{ zIndex: -c.layer, transform: `translate(${c.position * 10}% ,0)` }}>
                                    <img src={c.avatar} alt="" />
                                </div>
                            </React.Fragment>

                        })
                    }
                </div>
            </div>
            <div className="location-content">
                <TextBox
                    speaker={step.speaker}
                    name={step.narrator ? '' : step.name}
                    onNext={showNext ? onNext: undefined}
                >
                    {
                        step.elements.map((e: any) => {
                            if (e.type === 'dialog') {
                                if (clearBlockTags(e.text)) {
                                    return <div key={e.id} className="dialog" dangerouslySetInnerHTML={{ __html: parseRichText(e.text) }} />
                                }

                                return <></>
                            }
                            if (e.type === 'text' && e.text) {
                                return <div key={e.id} className="text" dangerouslySetInnerHTML={{ __html: parseRichText(e.text) }} />
                            }
                            if (e.type === 'action') {
                                return <ActionElement element={e} sendAction={sendAction} />
                            }

                            return <p>'???'</p>;
                        })
                    }
                </TextBox>
            </div>
        </div>
    </Container>
}

