import { useCallback } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Network from "../../network";

export default function useSendAction() {

    const client = useQueryClient();

    const { mutate, isLoading } = useMutation((id: string) => {
        return Network.sendAction(id)
    }, {
        async onSuccess() {
            await client.invalidateQueries(['user']);
            await client.invalidateQueries(['game-step']);
        }
    })

    return {
        isLoading,
        send: useCallback((actionId: string) => {
            mutate(actionId)
        }, [mutate])
    }
}
