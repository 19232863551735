import React from "react";
import styled from "styled-components";

import logoOcalimySwiat from '../../assets/logos/ocalimy-swiat.png';
import logoMinisterstwo from '../../assets/logos/ministerstwo-kis.png';
import logoBiblioteka from '../../assets/logos/biblioteka.png';

import logoABC from '../../assets/logos/abc.png';

import logoWWF from '../../assets/logos/wwf.png';

import logoING from '../../assets/logos/ing.png';
import logoVISA from '../../assets/logos/visa.png';

import logoOnet from '../../assets/logos/onet.png';
import logoWyborcza from '../../assets/logos/wyborcza.png';
import logoVictor from '../../assets/logos/victor.png';
import logoVictorJunior from '../../assets/logos/victor-junior.png';
import logoKumpel from '../../assets/logos/kumpel.png';
import logoRDC from '../../assets/logos/rdc.png';
import logoLibrus from '../../assets/logos/librus.png';
import logoSygnal from '../../assets/logos/sygnal.png';
import logoSzkola from '../../assets/logos/szkola.png';

import logoStrajk from '../../assets/logos/strajk.png';
import logoNatur from '../../assets/logos/natur.png';


const Container = styled.div`
    display: grid;

    grid-template-columns: repeat(6, 1fr);
    gap: 5px 1rem;
    width: 100%;

    h3 {
        border-top: 1px solid black;
        font-size: 1em;
    }

    .ekoprogram-title {
        grid-column: 1/4;
        grid-row: 1;
    }

    .ekoprogram-list {
        grid-column: 1/4;
        grid-row: 2;

        display: flex;
        width: 100%;
        justify-content: flex-start;
        flex-wrap: wrap;

        img {
            display: block;
            height: 4em;
            margin: .5em 2em .5em 0;
        }
    }

    .patroni-title {
        grid-column: 4/7;
        grid-row: 1;
    }

    .patroni-list {
        grid-column: 4/7;
        grid-row: 2;

        display: flex;
        width: 100%;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: center;

        img {
            display: block;
            height: 2em;
            margin: .5em 1em .5em 0;
        }
    }

    .organizator-title {
        grid-column: 1/3;
        grid-row: 3;
    }

    .organizator-list {
        grid-column: 1/3;
        grid-row: 4;

        display: flex;
        width: 100%;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: center;

        img {
            display: block;
            height: 2em;
            margin: .5em 2em .5em 0;
        }
    }

    .merytoryczny-title {
        grid-column: 3/5;
        grid-row: 3;
    }

    .merytoryczny-list {
        grid-column: 3/5;
        grid-row: 4;

        display: flex;
        width: 100%;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: center;

        img {
            display: block;
            height: 4em;
            margin: .5em 2em .5em 0;
        }
    }

    .strategiczny-title {
        grid-column: 5/7;
        grid-row: 3;
    }

    .strategiczny-list {
        grid-column: 5/7;
        grid-row: 4;

        display: flex;
        width: 100%;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: center;

        img {
            display: block;
            height: 1.5em;
            margin: .5em 1em .5em 0;
        }
    }

    .medialni-title {
        grid-column: 1/7;
        grid-row: 5;
    }

    .medialni-list {
        grid-column: 1/7;
        grid-row: 6;

        display: flex;
        width: 100%;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: center;

        img {
            display: block;
            height: 2em;
            margin: .5em 2em .5em 0;
        }
    }

    .wspolpraca-title {
        grid-column: 1/7;
        grid-row: 7;
    }

    .wspolpraca-list {
        grid-column: 1/7;
        grid-row: 8;

        display: flex;
        width: 100%;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: center;

        img {
            display: block;
            height: 4em;
            margin: .5em 2em .5em 0;
        }
    }


    @media (max-width: 1080px) {
        font-size: .8rem;
    }

`;

export default function FullLogos() {
    return <Container>
        <h3 className="ekoprogram-title">EKOPROGRAM Z CHARAKTEREM!</h3>
        <div className="ekoprogram-list">
            <img src={logoOcalimySwiat} alt="" />
        </div>


        <h3 className="patroni-title">Patroni honorowi</h3>
        <div className="patroni-list">
            <img src={logoMinisterstwo} alt="" />
            <img src={logoBiblioteka} alt="" />
        </div>


        <h3 className="organizator-title">Organizator</h3>
        <div className="organizator-list">
            <img src={logoABC} alt="" />
        </div>


        <h3 className="merytoryczny-title">Partner merytoryczny</h3>
        <div className="merytoryczny-list">
            <img src={logoWWF} alt="" />
        </div>


        <h3 className="strategiczny-title">Partnerzy strategiczni</h3>
        <div className="strategiczny-list">
            <img src={logoING} alt="" />
            <img src={logoVISA} alt="" />
        </div>


        <h3 className="medialni-title">Patroni medialni</h3>
        <div className="medialni-list">
            <img src={logoOnet} alt="" />
            <img src={logoWyborcza} alt="" />
            <img src={logoVictor} alt="" />
            <img src={logoVictorJunior} alt="" />
            <img src={logoKumpel} alt="" />
            <img src={logoRDC} alt="" />
            <img src={logoLibrus} alt="" />
            <img src={logoSygnal} alt="" />
            <img src={logoSzkola} alt="" />
        </div>


        <h3 className="wspolpraca-title">Współpraca</h3>
        <div className="wspolpraca-list">
            <img src={logoStrajk} alt="" />
            <img src={logoNatur} alt="" />
        </div>
    </Container>
}
