import { useMutation, useQueryClient } from "@tanstack/react-query";
import Network from "../../network";

export default function useSendCover() {

    const client = useQueryClient();

    const { mutate } = useMutation(({bgIndex, objIndex, titleIndex}: {bgIndex: number, objIndex: number, titleIndex: number}) => {
        return Network.saveGameCover(bgIndex, objIndex, titleIndex);
    }, {
        async onSuccess() {
            await client.invalidateQueries(['user']);
        }
    })

    return mutate;
}
