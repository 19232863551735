import classNames from "classnames";
import { Link } from "react-router-dom";
import styled from "styled-components";
import useNextChapter from "../../../modules/game/hooks/use-next-chapter";
import { getPlayerMenuRoute } from "../../../routes/routes";
import FullLogos from "../../full-logos";
import Loader from "../../loader";
import PageHeader from "../../page-header";
import ScrollToTop from "../../utils/scroll-to-top";
import endScreenSrc from '../../../assets/end-screen.png';

type Props = {
    chapterIndex: number;
    next?: 'chapter' | 'no-available' | 'game-end';
}

const Container = styled.div`

    width: 95%;
    margin: 0 auto;

    h2 {
        text-align: center;
        transform: scale(.89, 1);
        color: var(--color3);
        font-weight: normal;
    }

    p {
        width: 112%;
        text-align: center;
        transform: scale(.89, 1);
        transform-origin: 0;
    }

    .bigger {
        font-size: 1.5em;
    }

    .wrapper {
        overflow: hidden;

        display: flex;
        flex-direction: column;

        padding: 0 2em 1em;
    }

    .buttons {
        margin: -.5em 0 2em 0;

        display:flex;
        width: 100%;
        flex-direction: row;
        align-content: center;
        justify-content: space-between;

        position: relative;

        .loader {
            display: none;

            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            z-index: 10;
        }

        &.show-loader {
            .loader {
                display: block;
            }

            button, a {
                opacity: 0;
                pointer-events: none;
            }
        }

        @media(max-width: 1080px) {
            flex-direction: column-reverse;
            align-items: center;

            .button {
                margin: .5em;
            }
        }
    }

    .no-avaiable {
        p {
            margin: 1em 0;
        }

        .buttons {
            margin-top: 2em;
            justify-content: center;
        }
    }

    .button {
        text-decoration: none;
        padding: 1em 2em;
        border-radius: 1.5em;
        border: none;
        background: var(--action-main-color);
        border: 2px solid var(--action-main-color);
        color: white;
        text-transform: uppercase;
        font-size: 1rem;
        font-weight: 600;
        box-shadow: 0 3px 3px 1px #d9d9d9;

        margin: 0;

        cursor: pointer;

        display: inline-block;

        &:hover {
            background: var(--action-hover-color);
            border-color: var(--action-hover-color);
        }
    }

    .chapter-2 {
        background: #0F4A4E;
        border-color: #0F4A4E;

        &:hover {
            background: #30D6A0;
            border-color: #30D6A0;
        }
    }

    .chapter-3 {
        background: #ce6e18;
        border-color: #ce6e18;

        &:hover {
            background: #FFA31B;
            border-color: #FFA31B;
        }
    }

    .chapter-4 {
        background: #6237A0;
        border-color: #6237A0;

        &:hover {
            background: #BC95FF;
            border-color: #BC95FF;
        }
    }

    .chapter-5 {
        background: var(--color3);
        border-color: var(--color3);

        &:hover {
            background: var(--color1);
            border-color: var(--color1);
        }
    }
`;

export default function ChapterEndLogos({ chapterIndex, next }: Props) {
    const { nextChapter, isLoading: nextChapterLoading } = useNextChapter();

    const buttonClasses = classNames({
        "buttons": true,
        "show-loader": nextChapterLoading
    });

    return <Container>
        <div className="wrapper">
            <ScrollToTop behavior='smooth' />
            <PageHeader color1="var(--color3)" color2="var(--color6)" color3={'#18345a'} />
            <h2>koniec rozdziału {toRomans(chapterIndex)}</h2>
            <img src={endScreenSrc} alt="" />
            {next === 'chapter' && <div className={buttonClasses}>
                <div className="loader">
                    <Loader />
                </div>
                <Link className="button" to={getPlayerMenuRoute()}>Powrót do menu</Link>
                <button className={`button chapter-${chapterIndex + 1}`} onClick={_ => nextChapter()}>Rozpocznij rozdział {toRomans(chapterIndex + 1)}</button>
            </div>}
            {next === 'no-available' && <div className="no-avaiable">
                <p>
                    To na razie tyle. <br />
                    Wróć później, by kontynuować swoją przygodę!
                </p>
                <div className="buttons">
                    <Link className="button" to={getPlayerMenuRoute()}>Powrót do menu</Link>
                </div>
            </div>}
            <FullLogos />
        </div>
    </Container>
}

function toRomans(v: number): string {
    switch (v) {
        case 1: return 'I';
        case 2: return 'II';
        case 3: return 'III';
        case 4: return 'IV';
        case 5: return 'V';
    }

    return '' + v;
}
