import { useEffect } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router"
import AdminNetwork from "../../modules/network/admin";
import { getPlayerGameRoute } from "../routes";

export default function GameResetPage() {
    const {gameCode} = useParams<{gameCode: string}>();

    const client = useQueryClient();

    const navigate = useNavigate();

    const {mutate} = useMutation(['reset-game'], () => {
        return AdminNetwork.resetGame(gameCode || '');
    }, {
        onSuccess() {
            client.invalidateQueries(['game-step', gameCode], {
                refetchType: 'all'
            } ,{});
            navigate(getPlayerGameRoute());
        }
    });

    useEffect(() => {
        mutate();
    }, [mutate]);

    return <></>
}
