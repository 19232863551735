import { Fragment, useCallback, useEffect, useMemo, useState } from "react"
import styled from "styled-components";
import useSendAction from "../../modules/game/hooks/use-send-action";
import ActionPhase from "./action-phase";
import ContentPhase from "./content-phase";
import DialogPhase from "./dialog-phase";
import HeaderPhase from "./header-phase";

import classNames from "classnames";
import ScrollToTop from "../utils/scroll-to-top";
import useDebugTools from "../../modules/main/hooks/use-debug-tools";
import PhaseLoader from "./phase-loader";
import CustomPhase from "./custom-phase";
import Loader from "../loader";
import LocationPhase from "./location/location";
import EmbedPhase from "./embed-phase";
import GeneratorPage from "./generator";
import useSendCover from "../../modules/game/hooks/use-send-cover";

type Props = {
    step: any
}

const Container = styled.div`
    min-height: 100vh;

    &.inverted {

        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .wrapper {

        display: flex;
        flex-direction: column;
        justify-content: center;

        padding: 0 1em;
    }

    .step-actions,
    .step-content {
        overflow: hidden;
    }

    .step-actions {
        position: relative;
        margin-bottom: 1em;

        .loader {
            display: none;

            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            z-index: 10;
        }

        &.show-loader {
            .loader {
                display: block;
            }

            .actions-list {
                opacity: 0;
                pointer-events: none;
            }
        }
    }
`

export default function GameStepWidget({ step }: Props) {

    const showChapterTitle = Boolean(step.step.chapterTitleScreen);

    const containerClass = classNames([{
        inverted: showChapterTitle || step.step.invertTheme
    }], step.step.theme);

    const { send, isLoading } = useSendAction();

    const sendAction = useCallback((id: string) => {
        send(id);
    }, [send]);

    const { sections } = useStepViewSections(step.step.sections, sendAction);

    const actionsClasses = classNames({
        "step-actions": true,
        "show-loader": isLoading
    })

    const actions = sections.filter(p => p.container === 'actions');

    const showGeneratorPage = !!step.step.sections.find((s: any) => s.type === 'custom' && s.customType === 'generator');

    const sendCover = useSendCover();

    if (showGeneratorPage) {
        return <GeneratorPage onConfirm={function (bgIndex: number, objIndex: number, titleIndex: number): void {
            sendCover({bgIndex, objIndex, titleIndex});
            const action = step.step.sections.find((s: any) => s.type === 'action');
            sendAction(action?.id);
        } } />;
    }

    return <Container className={containerClass}>
        <ScrollToTop trigger={step} behavior='smooth' />
        <div className="wrapper">
            <div className="step-content">
                <SectionContainer stepSections={sections.filter(p => p.container === 'content')} />
            </div>
            {actions.length > 0 && <div className={actionsClasses}>
                <div className="loader">
                    <Loader />
                </div>
                <div className="actions-list">
                    <SectionContainer stepSections={actions} />
                </div>
            </div>}
        </div>
    </Container >
}

function SectionContainer({ stepSections }: { stepSections: StepSection[] }) {
    return <>
        {
            stepSections.map((stepSection, index: number) => {
                return <PhaseLoader key={index} visibility={stepSection.visibility} animation={stepSection.animation}>{stepSection.widget}</PhaseLoader>
            })
        }
    </>
}


type StepSection = {
    visibility: 'hide' | 'show' | 'preshow',
    animation: 'none' | 'fade-in',
    widget: any,
    container: 'content' | 'actions'
}

function useStepViewSections(stepSections: any[], sendAction: any): { sections: StepSection[] } {

    const [showActions, setShowActions] = useState(true);
    const [nextElementToLoad, setNextElementToLoad] = useState(0);

    useEffect(() => {
        setShowActions(stepSections.every(p => p.type !== 'custom'));
        setNextElementToLoad(0);
    }, [setShowActions, stepSections, setNextElementToLoad]);

    const nextPhase = useCallback(() => {
        setNextElementToLoad(v => {
            const newValue = (v < stepSections.length) ? v + 1 : v;
            return newValue;
        })
    }, [setNextElementToLoad, stepSections]);

    useEffect(() => {
        nextPhase();
        const id = setInterval(() => {
            nextPhase();
        }, 750);
        return () => {
            clearInterval(id);
        }
    }, [nextPhase]);

    const { disableAnimation } = useDebugTools();

    const sections: StepSection[] = useMemo(() => {
        return stepSections
            .map((p: any, index) => {

                function getVisibility() {
                    if (disableAnimation) return 'show';

                    if (index < nextElementToLoad) {
                        return 'show';
                    }

                    if (index === nextElementToLoad) {
                        return 'preshow';
                    }

                    return 'hide'
                }

                switch (p.type) {
                    case 'dialog':
                        return {
                            visibility: getVisibility(),
                            animation: 'none',
                            widget: <DialogPhase phase={p} />,
                            container: 'content'
                        }

                    case 'content':
                        if (p.isHeader) {
                            return {
                                visibility: getVisibility(),
                                animation: 'none',
                                widget: <HeaderPhase phase={p} />,
                                container: 'content'
                            }
                        }
                        return {
                            visibility: getVisibility(),
                            animation: 'none',
                            widget: <ContentPhase phase={p} />,
                            container: 'content'
                        }

                    case 'action':
                        return {
                            visibility: showActions ? getVisibility() : 'hide',
                            animation: 'none',
                            widget: <ActionPhase phase={p} sendAction={sendAction} />,
                            container: 'actions'
                        }


                    case 'custom':
                        return {
                            visibility: getVisibility(),
                            animation: 'none',
                            widget: <CustomPhase phase={p} onComplete={() => setShowActions(true)} />,
                            container: 'content'
                        }

                    case 'onLocation':
                        return {
                            visibility: getVisibility(),
                            animation: 'none',
                            widget: <LocationPhase section={p} sendAction={sendAction} key={p.id} />,
                            container: 'content'
                        }

                    case 'embed':
                        return {
                            visibility: getVisibility(),
                            animation: 'none',
                            widget: <EmbedPhase script={p.embed} />,
                            container: 'content'
                        }
                }

                return {
                    visibility: getVisibility(),
                    animation: 'none',
                    container: 'content',
                    widget: <Fragment />
                }
            })

    }, [stepSections, setShowActions, showActions, nextElementToLoad, sendAction, disableAnimation]);

    return {
        sections
    }
}
