import { useCallback } from "react";
import { MAIN_MODULE_ACTION, useMainModuleDispatch, useMainModuleState } from ".."

export default function useDebugTools() {

    const state = useMainModuleState();
    const dispatch = useMainModuleDispatch();

    return {
        forcedTheme: state.forcedTheme,
        disableAnimation: state.disableAnimation,
        setDisableAnimation: useCallback((disableAnimation: boolean) => {
            dispatch({
                type: MAIN_MODULE_ACTION.SET_DISABLE_ANIMATION,
                payload: {
                    disableAnimation
                }
            })
        }, [dispatch]),
        setFordedTheme: useCallback((theme: string | null) => {
            dispatch({
                type: MAIN_MODULE_ACTION.SET_FORCED_THEME,
                payload: {
                    theme
                }
            })
        }, [dispatch])
    }
}
