import { useQuery } from "@tanstack/react-query";
import Network from "../../network";

export function useUser(logged?: boolean) {

    let queryFn = Network.getUser;

    if (logged === true) {
        queryFn = Network.getLoggedUser;
    }

    if (logged === false) {
        queryFn = Network.getCommonUser;
    }

    const { data, isLoading } = useQuery({
        queryKey: ['user', (logged ? 'logged' : 'open')],
        queryFn,
        staleTime: Infinity,
        retry(failureCount, error: any) {
            if (error?.response?.status === 403) {
                return false;
            }

            return failureCount < 3;
        },
    });

    return {
        data,
        isLoading
    }
}
