import { useEffect } from "react";
import styled from "styled-components";
import Loader from "../../components/loader";
import PlayerWrapper from "../../components/player-wrapper";
import loginBgSrc from '../../assets/login-background.jpg';
import abcLogoSrc from '../../assets/logos/ocalimy-swiat.png';
import PageHeader from "../../components/page-header";
import { useUser } from "../../modules/user/hooks/use-user";
import { getPlayerMenuRoute } from "../routes";
import { useNavigate } from "react-router";
import useOpenUser from "../../modules/user/hooks/use-open-user";

const Container = styled.div`

    min-height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;

    background: var(--color1);

    .bg {
        flex: 1 1 100%;

        display: flex;
        flex-direction: column;
        align-items: center;

        width: 95%;

        background: url(${loginBgSrc}) 50% 50% no-repeat;
        background-size: contain;
    }

    .content {
        flex: 1 1 100%;

        position: relative;


        width: 95%;

        display: flex;
        flex-direction: column;
        justify-content: center;
    }



    .loader {
        position: absolute;
        top: 30px;
        left: 30px;
        right: 30px;
        bottom: 30px;
    }

    .footer {
        width: 100%;
        .footer-inner {
            width: 90%;
            max-width: 800px;

            margin: 0 auto;

            display: flex;
            justify-content: center;
            align-items: center;

            background: white;
            border-radius: 2em 2em 0 0;

            padding: 1em 0;

            a {
                display: block;
            }

            img {
                display: block;
                height: 100px;
                max-width: 100%;
                object-fit: contain;
            }
        }

    }

    .login-form {

        display: flex;
        flex-direction: column;
        align-items: center;

        h2 {
            color: var(--color3);
            font-family: var(--audiowide);
            font-weight: 300;
            transform: scale(.89, 1);

            text-align: center;
        }

        p {
            text-align: center;
        }

        .info {
            margin: 1em 0;

            transform: scale(.89, 1);
        }

        input[type=text] {
            display: block;
            height: 2em;
            border-radius: 1.5em;
            border: none;

            font-size: 1rem;

            padding: .5em 1em;
            margin: 2em 0;

            box-shadow: inset .18em .18em .15em 0 rgba(53,73,94, .45);

            width: 75%;
            max-width: 750px;
            min-width: 300px;

            text-align: center;
        }

        .input-wrapper {
            margin: 0 auto 1.5em;
            display: flex;
            justify-content: center;
        }

        &.error {
            input[type=text] {
                background: var(--colorError2);
                color: white;
            }
        }

        .buttons {
            margin: 1em;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        button {
            font-size: 1em;
            text-decoration: none;
            padding: 1em 4em;
            border-radius: 1.6em;
            border: none;
            background: var(--color3);
            color: white;
            text-transform: uppercase;

            display: block;

            box-shadow: 0 .18em .15em 0 rgba(53,73,94, .45);

            font-weight: 600;

            &:hover {
                cursor: pointer;
                background: var(--color2);
            }
        }

        .error {
            color: var(--colorError1);
            font-weight: bold;
            margin: 1em 0;
        }
    }

`

export default function OpenPlayerMainPage() {
    const {login, isLoading} = useOpenUser();
    const {data: user, isLoading: userLoading} = useUser(false);

    const showLoader =  userLoading || isLoading;

    const navigate = useNavigate();

    useEffect(() => {
        login();
    }, [login]);

    useEffect(() => {
        console.log({user});
        if(user) {
            navigate(getPlayerMenuRoute());
        }
    }, [user, navigate]);

    return <PlayerWrapper>
        <Container>
            <div className="bg">
                <PageHeader color1="white" color2="var(--color3)" color3={'#18345a'} />
                <main className="content">
                    {showLoader && <div className="loader">
                        <Loader />
                    </div>}
                </main>
            </div>
            <footer className="footer">
                <div className="footer-inner">
                    <a href="https://calapolskaczytadzieciom.pl/ocalimy-swiat/" target="_blank" rel="noreferrer">
                        <img src={abcLogoSrc} alt="Fundacja ABC. Całą polska czyta dzieciom" />
                    </a>
                </div>
            </footer>
        </Container>
    </PlayerWrapper>
}
