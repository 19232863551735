import styled from "styled-components"
import { useUser } from "../../../modules/user/hooks/use-user";
import Loader from "../../loader";
import ScrollToTop from "../../utils/scroll-to-top";
import GameEndEndPage from "./end-page";
import { Navigate } from "react-router";
import { getPlayerMenuRoute } from "../../../routes/routes";

const Container = styled.div`

    h2 {
        text-align: center;
    }

    .wrapper {
        overflow: hidden;

        display: flex;
        flex-direction: column;

        padding: 0 2em 1em;
    }
`;

export default function GameEnd() {

    const {data: user, isLoading} = useUser();

    return <>
        {isLoading && <Loader />}
        {!!user && <EndGameRouter user={user} />}
    </>
}

type RouterProps = {
    user: any;
}

function EndGameRouter({user}: RouterProps) {
    if (user.endingType && user.endingType !== 'success') {
        return <Navigate to={getPlayerMenuRoute()} />
    }

    return <Container>
        <ScrollToTop behavior='smooth' />
        <GameEndEndPage />
    </Container>
}
