import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import styled from 'styled-components';

type Props = {
    visibility: 'hide' | 'show' | 'preshow',
    animation: 'none' | 'fade-in',
}

export default function PhaseLoader({ children, visibility, animation }: PropsWithChildren<Props>) {

    return <LoaderContainer className={classNames(visibility, animation)} >{children}</LoaderContainer>
}

const LoaderContainer = styled.div`
    &.hide {
        transition: transform 0s, opacity 0s;
        transform: translate(100vw, 0);
        opacity: 0;
        pointer-events: none;
    }

    &.preshow {
        opacity: 0;
        pointer-events: none;
        transition: transform 0s, opacity 0s;
        transform: translate(100vw, 0);
    }

    opacity: 1;
    transition: transform .5s, opacity 1s;
    transform: translate(0, 0);
`;
