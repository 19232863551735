import styled from "styled-components";
// eslint-disable-next-line
import cyberCircle from '!file-loader!../../assets/cyber-circle.svg';

const Loader = styled.div < { color?: 'blue' }> `
    background: url(${cyberCircle}) 50% 50% no-repeat;
    background-size: contain;

    width: 100%;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;


    ${({ color }) => {
        switch (color) {
            case 'blue':
            default:
                return 'filter: invert(44%) sepia(59%) saturate(560%) hue-rotate(161deg) brightness(89%) contrast(99%);'
        }
    }
    }

    animation:spin 2s linear infinite;

    @keyframes spin {
        100% {
            transform:rotate(360deg);
        }
    }
`;

export default Loader;
