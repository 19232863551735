import { Link } from "react-router-dom";
import styled from "styled-components";
import titleScreenImgSrc from '../../assets/title-screen.jpg';
import { getPlayerGameRoute } from "../../routes/routes";
import abcLogoSrc from '../../assets/logos/ocalimy-swiat.png';
import PageHeader from "../page-header";

const Container = styled.div`
    min-height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;

    .content {
        flex: 1 1 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        padding-bottom: 2em;

        img {
            display: block;
            max-width: 95vw;
            margin: 0 auto;
            max-height: 90vh;
        }

        .button {
            text-decoration: none;
            padding: 1em 2em;
            border-radius: 1.5em;
            border: none;
            background: var(--color3);
            color: white;
            text-transform: uppercase;

            display: inline-block;
        }
    }


    .footer {
        width: 100%;
        .footer-inner {
            width: 90%;
            max-width: 800px;

            margin: 0 auto;

            display: flex;
            justify-content: center;
            align-items: center;

            background: white;
            border-radius: 2em 2em 0 0;

            padding: 1em 0;

            a {
                display: block;
            }

            img {
                display: block;
                height: 100px;
                max-width: 100%;
                object-fit: contain;
            }
        }

    }
`;

export default function GameTitle() {
    return <Container>

        <PageHeader color1="var(--color3)" color2="var(--color6)" color3={'#18345a'} />
        <main className="content">
            <img src={titleScreenImgSrc} alt="" />
            <Link to={getPlayerGameRoute()} className="button">Rozpocznij przygodę</Link>
        </main>

        <footer className="footer">
            <div className="footer-inner">
                <a href="https://calapolskaczytadzieciom.pl/ocalimy-swiat/" target="_blank" rel="noreferrer">
                    <img src={abcLogoSrc} alt="Fundacja ABC. Całą polska czyta dzieciom" />
                </a>
            </div>
        </footer>
    </Container>
}
