import { ErrorMessage } from "@hookform/error-message";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";

const Container = styled.div`
    .error {
        font-size: .8em;
        color: red;
    }
`;

type Props = {
    onComplete(): void
}

export default function SongWidget({onComplete}: Props) {

    const { register, handleSubmit, formState, clearErrors } = useForm({ reValidateMode: 'onSubmit' });

    const onSubmit = useCallback((data) => {
        console.log(data);
        onComplete();
    }, [onComplete]);

    const errorRender = useCallback(({ message }) => {
        return <span className="error">{'\u00A0'} - {message}</span>
    }, []);

    return <Container>
        <form onSubmit={handleSubmit(onSubmit)}>
            <p>
                Wlazł kotek<br />
                na <input type="text" {...register('song-q1', {
                    validate: {
                        empty: v => Boolean(v) || 'uzupełnij pole',
                        correct: (v: string) => v.trim().toLowerCase() === 'płotek' || 'niepoprawna odpowiedź'
                    },
                    onChange: () => {
                        clearErrors('song-q1');
                    }
                })} />
                <ErrorMessage
                    errors={formState.errors}
                    name='song-q1'
                    render={errorRender}
                />
                <br />
                i <input type="text" {...register('song-q2', {
                    validate: {
                        empty: v => Boolean(v) || 'uzupełnij pole',
                        correct: (v: string) => v.trim().toLowerCase() === 'mruga' || 'niepoprawna odpowiedź'
                    },
                    onChange: () => {
                        clearErrors('song-q2');
                    }
                })} />
                <ErrorMessage
                    errors={formState.errors}
                    name='song-q2'
                    render={errorRender}
                /><br />
            </p>
            <p>
                Ładna to <input type="text" {...register('song-q3', {
                    validate: {
                        empty: v => Boolean(v) || 'uzupełnij pole',
                        correct: (v: string) => v.trim().toLowerCase() === 'piosenka' || 'niepoprawna odpowiedź'
                    },
                    onChange: () => {
                        clearErrors('song-q3');
                    }
                })} />
                <ErrorMessage
                    errors={formState.errors}
                    name='song-q3'
                    render={errorRender}
                />,<br />
                nie <input type="text" {...register('song-q4', {
                    validate: {
                        empty: v => Boolean(v) || 'uzupełnij pole',
                        correct: (v: string) => v.trim().toLowerCase() === 'długa' || 'niepoprawna odpowiedź'
                    },
                    onChange: () => {
                        clearErrors('song-q4');
                    }
                })} />
                <ErrorMessage
                    errors={formState.errors}
                    name='song-q4'
                    render={errorRender}
                />
            </p>

            <button>Sprawdź</button>
        </form>
    </Container>
}
