import { useCallback } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import styled from "styled-components";
import AdminNetwork from "../../../modules/network/admin";
import { SurveyQuestion, SurveyQuestionType, SURVEY_LIST } from "./data";

type Props = {
    code: string,
    onComplete(): void
}

const Container = styled.div`

    .buttons {
        display: flex;
        justify-content: center;
    }

    button {
        font-size: 1em;
        text-decoration: none;
        padding: 1.1em 5em;
        border-radius: 1.6em;
        border: none;
        background: var(--action-main-color);
        color: white;
        text-transform: uppercase;

        display: block;

        box-shadow: 0 .18em .15em 0 rgba(53,73,94, .45);

        font-weight: 600;

        &:hover {
            cursor: pointer;
            background: var(--action-hover-color);
        }
    }
`;

export default function Survey({ code, onComplete }: Props) {
    const { survey } = useSurvey(code);

    const { id, questions } = survey;
    const form = useForm();
    const { handleSubmit } = form;

    const { mutate } = useMutation(['survey-send'], (data: any) => {
        return AdminNetwork.sendSurvey(id, data);
    });

    const onSubmit = useCallback((data) => {
        const surveyData: { [key: string]: any } = {};

        Object.keys(data).forEach(id => {
            const value = data[id];
            const info = survey.questions.find(q => q.id === id);

            if (info) {
                switch (info.type) {
                    case SurveyQuestionType.grade:
                    case SurveyQuestionType.trueFalse:
                        surveyData[id] = value ? parseInt(data[id]) : null;
                        break;

                    default:
                        surveyData[id] = value;
                }
            }
        });

        console.log(surveyData);

        mutate(surveyData, {
            onSuccess: () => {
                onComplete();
            },
            onError: () => {
                onComplete();
            }
        });

    }, [survey, mutate, onComplete]);

    return <Container>
        <FormProvider {...form}>
            <form onSubmit={handleSubmit(onSubmit)}>
                {
                    questions.map((q) => {
                        return <SurveyQuestionWidget question={q} key={q.id} />
                    })
                }
                <div className="buttons">
                    <button>Wyślij</button>
                </div>
            </form>
        </FormProvider>
    </Container>
}

const QuestionContainer = styled.div`
    margin: .5em 2em;
`;

function SurveyQuestionWidget({ question }: { question: SurveyQuestion }) {

    const { register } = useFormContext();

    return <QuestionContainer>
        {question.type === SurveyQuestionType.text && <div>
            <label>
                <p>{question.label}</p>
                <input {...register(question.id)} />
            </label>
        </div>}
        {question.type === SurveyQuestionType.bigText && <div>
            <label>
                <p>{question.label}</p>
                <textarea {...register(question.id)} />
            </label>
        </div>}
        {question.type === SurveyQuestionType.grade && <div>
            <p>{question.label}</p>
            <label>
                1 <input type='radio' value={1} {...register(question.id)} />
            </label><br />
            <label>
                2 <input type='radio' value={2} {...register(question.id)} />
            </label><br />
            <label>
                3 <input type='radio' value={3} {...register(question.id)} />
            </label><br />
            <label>
                4 <input type='radio' value={4} {...register(question.id)} />
            </label><br />
            <label>
                5 <input type='radio' value={5} {...register(question.id)} />
            </label>
        </div>}
        {question.type === SurveyQuestionType.trueFalse && <div>
            <label>
                <p>{question.label}</p>
                <label>
                    TAK <input type='radio' value={1} {...register(question.id)} />
                </label><br />
                <label>
                    NIE <input type='radio' value={0}  {...register(question.id)} />
                </label>
            </label>
        </div>}

    </QuestionContainer>
}


function useSurvey(code: string) {
    return {
        survey: SURVEY_LIST[code],
        isLoading: false
    }
}
