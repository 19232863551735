import styled from "styled-components"

type Props = {
    color1: string,
    color2: string,
    color3: string
}

const Container = styled.header<Props>`
    h1 {
        font-family: var(--audiowide);
        font-weight: 300;
        color: ${({color1}) => color1};
        font-size: 3em;

        margin: .3em 0 0;
        padding: 0;

        text-align: center;

        span {
            color: ${({color2}) => color2};
        }

    }

    h2 {

        display: flex;
        align-items: center;


        padding: 0;
        margin: 0 0 1em;
        font-family: var(--audiowide);
        text-transform: uppercase !important;
        font-weight: 300;
        text-align: center;

        color: ${({color3}) => color3} !important;

        &:after,
        &:before {
            flex: 1 1 100%;
            content: '';
            border-top: 1px solid ${({color3}) => color3} !important;
            border-bottom: 1px solid ${({color3}) => color3} !important;
            height: 0;
        }
    }

    transform: scale(.89, 1);
`

export default function PageHeader(props: Props) {
    return <Container {...props}>
        <h1><span>EKO</span>HAKATON</h1>
        <h2>{'\u00A0'}Latający{'\u00A0'}talerz{'\u00A0'}</h2>
    </Container>
}
