import styled from "styled-components"
import { parseRichText } from "./rich-text"

const Container = styled.div`
    margin: 1em 0;

    display: flex;
    flex-direction: column;
    align-items: center;



    img {
        max-width: 100%;
        max-height: 66vh;

        display: block;
        margin-bottom: 1em;
    }

    .text-content {
        width: 112%;
        transform-origin: center;
        transform: scale(.89,1);

        a {
            color: var(--link-color);
            text-decoration: none;

            &:hover {
                color: var(--link-hover-color);
            }
        }
    }

    ul, ol {
        width: 90%;
        margin: 0 auto;
    }

    li {
        margin: .5em 0;
    }

    ul {
        li::marker {
            color: var(--list-bullet-color);
            font-size: 1.2em;
        }
    }
`

export default function ContentPhase({ phase }: any) {

    // phase.text = '<align=left>afasdfaefaef\n2. fsefsefs</align>\n<align=left>2. fsefsgsrg</align>\n<align=left>faaeafaefaef\ngsrgsrfsefaefagargafef</align>\n    * <align=left>srsfsrgf\n    * <align=center>srgsfsgfshgshsr\n    * <align=right>srfgsghsrfsaefafgvafaga\n    * <align=right>agfafaef\n      Agasgafaefagargarg\n    * <align=left>aefaefagfa\n\n    1. <align=left>aefagfa\n    2. <align=left>aefaegahhsrhsg\n    3. <align=left>sefafaegghasrgaf\n\n<align=left>afeaefadfa</align>\n<align=left>aefd<link=\"http://www.wp.pl/\">aga</link>ahera</align>\n<align=left></align>\n<align=left></align>\n<align=left>aefaefgaeg</align>';

    if (!phase.text && !phase.image) {
        return <></>
    }

    return <Container>
        {phase.image && <img src={phase.image} alt='' />}
        {phase.text && <div className="text-content" dangerouslySetInnerHTML={{__html: parseRichText(phase.text)}}></div>}
    </Container>
}



