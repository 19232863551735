import { useState } from "react";
import { ChapterEndingProps } from ".";
import ChapterEndLogos from "./chapter-end-logos";
import ChapterScoresScreen from "./chapter-scores-screen";

export default function EndChapter({ chapterIndex, next, testerSurvey }: ChapterEndingProps) {

    const [screen, setScreen] = useState<'score' | 'end'>('score');

    return <>
        <div className="wrapper">
            {screen === 'score' && <ChapterScoresScreen chapterIndex={chapterIndex}  testerSurvey={testerSurvey} onNext={() => setScreen('end')} />}
            {screen === 'end' && <ChapterEndLogos chapterIndex={chapterIndex} next={next} />}
        </div>
    </>
}
