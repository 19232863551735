import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router";
import { getGameMasterLiveGameRoute } from "../../../routes/routes";
import Network from "../../network";

export default function useCreateNemGame() {

    const navigate = useNavigate();

    const { mutate, isLoading, data } = useMutation(['new-game'], Network.createNewGame, {
        onSuccess(data) {
            navigate(getGameMasterLiveGameRoute(data.code));
        }
    });

    return {
        createNewGame: mutate,
        isLoading,
        newGameData: data
    }
}
