import makeStore from "@mborecki/react-create-store";
import reducer from "./reducer";

const MODULE_LS_KEY = 'polskoczytaj:user'

export type UserModuleState = {
    userId?: string
}

export const initUserModuleState: UserModuleState = {
}

export enum USER_MODULE_ACTION {
    SET_USER_ID,
    RESET_USER_ID
}

export type UserModuleAction = {
    type: USER_MODULE_ACTION,
    payload?: any
}

const [
    UserModuleProvider,
    useUserModuleDispatch,
    useUserModuleState
] = makeStore(reducer, initUserModuleState, MODULE_LS_KEY)


export {
    UserModuleProvider,
    useUserModuleDispatch,
    useUserModuleState
}
