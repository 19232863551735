import { useEffect } from "react";
import { useNavigate } from "react-router";
import styled, { createGlobalStyle } from "styled-components";
import PageHeader from "../../components/page-header";
import PlayerWrapper from "../../components/player-wrapper";
import UserProfile from "../../components/user-profile";
import { useUser } from "../../modules/user/hooks/use-user";
import { getPlayerIntroRoute } from "../routes";

const Container = styled.div`
    min-height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: stretch;

    background: white;

    .wrapper {
        overflow: hidden;

        display: flex;
        flex-direction: column;

        padding: 0 2em 1em;
    }
`;

const PageBackgroud = createGlobalStyle`
    body {
        background: var(--color1);
    }
`;

export default function PlayerMenuPage() {

    const { data, isLoading } = useUser();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isLoading && !data) {
            navigate(getPlayerIntroRoute());
        }
    }, [data, isLoading, navigate]);

    return <PlayerWrapper>
        <PageBackgroud />
        <Container>
            <div className="wrapper">
                <PageHeader color1="var(--color3)" color2="var(--color6)" color3={'#18345a'} />
                {isLoading && <p>Ładuję...</p>}
                {data && <UserProfile user={data} />}
            </div>
        </Container>
    </PlayerWrapper>
}
