import { useQuery } from "@tanstack/react-query";
import AdminNetwork from "../../network/admin";

export default function useGMScenarios() {
    const { data, isLoading } = useQuery(['scenarios'], AdminNetwork.getScenarioList);

    return {
        scenarios: data,
        isLoading
    }
}
