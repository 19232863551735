import GameEnd from "../game-end";
import EndChapter from "./end-screen";

export type ChapterEndingProps = {
    chapterIndex: number
    next?: 'chapter' | 'no-available' | 'game-end',
    testerSurvey: boolean
}

export default function ChapterEnding(props: ChapterEndingProps) {
    const {next} = props;

    const showGameEndScreen = next === 'game-end';
    const showChapterEndScreen = (next === 'chapter') || (next === 'no-available');

    return <>
        {showGameEndScreen && <GameEnd />}
        {showChapterEndScreen && <EndChapter {...props} />}
    </>
}
