import { Link } from "react-router-dom";
import styled from "styled-components";
import NewGameForm from "../../../components/new-game-form";
import { getGameMasterRoute } from "../../routes";

const Container = styled.div`
    min-height: 100vh;
    width: 100%;
    max-width: 768px;
    margin: 0 auto;

    @media (min-width: 1080px) {
        max-width: 1080px;
    }

    color: var(--step-content-color);

    display: flex;
    flex-direction: column;
    align-items: stretch;
`;

export default function GameMasterCreateNewGamePage() {

    return <Container>
        <h1>Załóż nową grę</h1>
        <nav>
            <Link to={getGameMasterRoute()}>Powrót</Link>
        </nav>
        <NewGameForm />
    </Container>
}
