import { Link } from "react-router-dom";
import styled from "styled-components";
import { getPrivatePoliticsRoute } from "../routes";

const Container = styled.footer`
    max-width: 1080px;

    background: #f1f1f1;
    padding: 2em 2em 1em;

    border-radius: 2em 2em 0 0;

    font-size: 16px;

    .footer-wrapper {
        width: 100%;
        display: flex;
        justify-content: space-around;
    }

    a {
        color: var(--color3);
        text-decoration: none;

        &:hover {
            color: var(--color2);
        }
    }
`;

export default function HomeFooter() {
    return <Container>
        <div className="footer-wrapper">
            <Link to={getPrivatePoliticsRoute()}>Polityka prywatności</Link>
        </div>
    </Container>
}
